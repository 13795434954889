import { AuthenticationService } from '@alevate/serrala-auth-lib';
import { Injectable } from '@angular/core';
import { BaseService } from '@alevate/serrala-common/services';
import { Observable } from 'rxjs';
import {
  DEFAULT_MENU_PERMISSIONS,
  MENU_PERMISSION,
  MENU_PERMISSION_MAP,
} from './menu.config';
import { bottomMenu, bottomMenuItemsWhichNeedPermssions, menu } from './menu';
import { ApiConstant, Constants } from '@alevate/serrala-common/constants';

@Injectable()
export class MenuService extends BaseService {
  menuItems: any[];
  bottomMenuItems: any[];
  permitterMenuIds: number[];
  private cashAppMenuIds = [3200, 3210, 3220]; // Add menuIds to hide from the menu
  private filteredMenuItems: number[] = [...this.cashAppMenuIds];
  disabledFeatures: any;
  constructor(private authService: AuthenticationService) {
    super();
    this.menuItems = [];
    this.bottomMenuItems = [];
    if (sessionStorage.getItem('permittedMenuItems')) {
      this.permitterMenuIds = JSON.parse(
        sessionStorage.getItem('permittedMenuItems')
      );
    }
  }

  addMenu(): void {
    this.menuItems = this.filterMenuItems(menu);
  }

  addBottomMenu(): void {
    const permittedMenuItems = [];
    bottomMenuItemsWhichNeedPermssions.forEach((ele) => {
      if (this.permitterMenuIds.includes(ele.moduleId)) {
        permittedMenuItems.push(ele);
      }
    });
    this.bottomMenuItems = this.filterMenuItems([
      ...permittedMenuItems,
      ...bottomMenu,
    ]);
  }

  getMenu(): any[] {
    return this.menuItems;
  }

  getBottomMenu(): any[] {
    return this.bottomMenuItems;
  }

  /**
   * Checks the presense of each menu item's module id in the permitterMenuIds arrsy
   * and returns the filtered menu config list.
   */
  filterMenuItems(items: any[]): any[] {
    items.forEach((item) => {
      if (item.submenu) {
        const temparr = item.submenu.filter((ele) =>
          this.permitterMenuIds.includes(ele.moduleId)
        );
        item.submenu = temparr;
        item.submenu.map((element) => {
          if (element.submenu) {
            const temparr = element.submenu.filter((ele) =>
              this.permitterMenuIds.includes(ele.moduleId)
            );
            element.submenu = temparr;
          }
        });
      }
    });
    const token = this.authService.getTokenData();
    if (token['context'] === Constants.PLATFORM_USER_TYPES.context.SYSTEM) {
      const id = items.findIndex(
        (x) => x.key === Constants.PLATFORM_USER_TYPES.KEYS.TENANT
      );
      if (id > -1) {
        items = items.filter(
          (item) => item.key !== Constants.PLATFORM_USER_TYPES.KEYS.TENANT
        );
      }
    } else if (
      token.context === Constants.PLATFORM_USER_TYPES.context.OPERATOR
    ) {
      const index = items.findIndex(
        (x) => x.key === Constants.PLATFORM_USER_TYPES.KEYS.TENANT
      );
      if (index > -1) {
        items[index].text = 'CORE.menu.operator';
        items[index].image = '/images/operator.svg';
        items[index].submenu[0].text = 'CORE.menu.operatorList';
      }
    }
    return items;
  }

  /**
   * Returns the list of permitted menu items from the backend.
   */
  getMenuPermissionsFromServer(): Observable<string[]> {
    return this.httpClient.get<string[]>(
      ApiConstant.constants.API_ENDPOINTS.policy.menuPermissions,
      {
        params: {
          activity: MENU_PERMISSION,
        },
      }
    );
  }

  /**
   * Returns the list of module ids of the menu items by matching
   * the response and MENU_PERMISSION_MAP.
   */
  getMenuItemIds(response: string[]): number[] {
    const arr = [];
    Object.entries(MENU_PERMISSION_MAP).forEach((element) => {
      if (response.includes(element[0])) {
        arr.push(element[1]);
      }
    });
    return arr;
  }

  /**
   * Stores the list of menu module ids in the session storage.
   */
  setPermittedMenuIds(response: string[], value: boolean): void {
    const menuIdsFromServer = this.getMenuItemIds(response);
    this.permitterMenuIds = [...DEFAULT_MENU_PERMISSIONS, ...menuIdsFromServer];
    if (!value) {
      this.permitterMenuIds = this.permitterMenuIds.filter(id => !this.filteredMenuItems.includes(id));
    }
    sessionStorage.setItem(
      'permittedMenuItems',
      JSON.stringify(this.permitterMenuIds)
    );
  }
}
