/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export class Constants {
  static themesArr = [{ value: 'default-theme', text: 'Brand Light theme' }];

  static defaultTheme = 'default-theme';
  static PDF = 'pdf';

  static LANGUAGE_ARR = [
    { code: 'en', text: 'English' },
    { code: 'de', text: 'Deutsch' },
  ];

  static HTTP_ERROR_CODES = {
    BAD_REQUEST: 'BAD_REQUEST',
  };

  static defaultLanguage = 'en';

  static defaultDatePipeLanguage = 'en-US';

  static states = {
    Active: 'Active',
    Inactive: 'InActive',
  };
  static FORMATS = {
    SEPA_CT: 'SEPA-CT',
  };

  static CONTACT_TYPE_PRIMARY = 'PRIMARY';

  static OBJECT_NAME = {
    ATTRIBUTES: 'Attributes',
  };

  static ATTRIBUTE_MAPPING_KEYS = {
    LOCAL_ACCOUNT_TYPE_CODE: 'Local Account Type Code',
  };

  static dropDownTypes = {
    emailTypes: [
      { id: 0, val: '' },
      { id: 1, val: 'Primary' },
      { id: 2, val: 'Secondary' },
      { id: 3, val: 'Other' },
    ],
    titleTypes: [
      { id: 1, val: '' },
      { id: 2, val: 'Mr' },
      { id: 3, val: 'Ms' },
      { id: 4, val: 'Mrs' },
      { id: 5, val: 'Dr' },
    ],
    contactTypes: [
      { id: 0, val: '' },
      { id: 1, val: 'Primary' },
      { id: 2, val: 'Secondary' },
      { id: 3, val: 'Whatsapp' },
      { id: 4, val: 'Fax' },
      { id: 5, val: 'Other' },
    ],
    paymentParty: ['New', 'Party'],
    downloadOptions: ['Download and Delete', 'Download and Archive'],
    fileEncoding: [
      { id: 1, name: 'UTF-8' },
      { id: 2, name: 'ASCII' },
    ],
    fileFormats: [
      { id: 1, name: 'SEPACT' },
      { id: 2, name: 'MT101' },
      { id: 3, name: 'SDD' },
      { id: 4, name: 'Others' },
      { id: 5, name: 'Pain 002' },
      { id: 6, name: 'MT 940' },
      { id: 7, name: 'MT 942' },
      { id: 8, name: 'camt 052' },
    ],
    messageCategoryType: [
      { name: 'Delivery', value: 'Delivery' },
      { name: 'Non Delivery', value: 'Non Delivery' },
      { name: 'Transmission', value: 'Transmission' },
    ],
  };
  static validationPatterns = {
    phonePattern:
      /^^\s*(?:\+?(\d{1,3}))?[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/,
    emailPattern:
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,4})(\.[a-zA-Z]{2,4})?$/,
    portPattern: /^\d{0,8}$/,
    alphaNumericPattern: /^[a-zA-Z0-9 ]+$/,
    alphaNumericPatternWithoutSpace: /^[a-zA-Z0-9]+$/,
    nameFieldPattern: /^[a-zA-Z0-9 ,._-]+$/,
    bicValidation: /^[a-zA-Z0-9]+$/,
    DNValidation:
      /^(?=[^,]+(,[^,]+){1,9}$)(?=.{1,100}$)((cn=(([a-z0-9\-]{2}|[%][0-9]{2})|(?![0-9]+,)[a-z0-9\-]{2,20}),)?(ou=([a-z0-9\-]{2}|(?![0-9]+,)[a-z0-9\-]{2,20}),)*)?(o=([a-z]{4})([a-z]{2})(([0-9a-z]{1})([0-9a-np-z]{1}))(|([xX]{3})|),){1}o=swift$/,
    numericPattern: /^\d+$/,
    ibanNumberPattern: /^[A-Za-z]{2}\d{2}$/,
    keyPattern:
      /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9~!@#$%^&*()+`={}|[\]\:\/";\'<>?,.\\_-]{17,32}$/,
    alphaNumericWithoutSpecialChar: /^[a-zA-Z0-9]+(?:[a-zA-Z0-9]+)*$/,

  };
  static HtmlType = {
    textbox: 'textbox',
    textarea: 'textarea',
    lookup: 'lookup',
    datetimepicker: 'datetimepicker',
    datepicker: 'datepicker',
    numberbox: 'numberbox',
    dropdown: 'dropdown',
    toggle: 'toggle',
  };
  static COMMUNICATIONSETTINGS = {
    ERP: 'ERP',
    MQ: 'MQ',
    SFTP: 'SFTP',
    SWIFT_FILE_ACT: 'SWIFT_FILE_ACT',
    identifer: 'Identifier',
    edit: 'edit',
    infoCategories: {
      PAYMENTMESSAGE: 'PAYMENTMESSAGE',
      STATUSMESSAGE: 'STATUSMESSAGE',
      ACCOUNTSTATEMENTS: 'ACCOUNTSTATEMENTS',
      INTRADAYSTATEMENTS: 'INTRADAYSTATEMENTS',
    },
  };

  static STATUS = {
    IMPORTSUCCESSFUL: 'Import Successful',
    NEW: 'New',
    EXECUTION_DATE_CHANGED: 'Execution Date Changed',
    ITEM_DELETED: 'Item Deleted',
    ITEM_REMOVED: 'Item Removed',
    APPROVED: 'Approved',
    REJECTED: 'Rejected',
    SENT: 'Sent',
    DUPLICATE: 'Duplicate',
    DUPLICATE_ITEM: 'Duplicate Item',
    IMPORT_ERROR: 'Import Failed',
    TRANSMISSION_FAILED: 'Transmission Failed',
    ACTIVE: 'Active',
    INACTIVE: 'Inactive',
    EXPIRED: 'Expired',
  };

  static STORAGEKEYS = {
    PAYMENTMESSAGE: 'Payment Message',
    PAYMENTITEM: 'Payment Item',
    FILEPAYMENTITEM: 'File Payment Item',
    STATUSMESSAGE: 'Status Message',
    ACCOUNTSTATEMENTMESSAGE: 'Account Statement Message',
  };

  static CHATBOTMESSAGES = {
    sera: 'Serra',
    popMsg: 'Assistant Serra is ready to help.<br>Try now',
    nospeech: 'no-speech',
    introMsg: 'What can I do for you?',
    sentBy: {
      user: 'user',
      bot: 'bot',
    },
    greetings: {
      gm: 'Good Morning',
      ge: 'Good Evening',
      ga: 'Good Afternoon',
    },
    language: 'en-uk',
    Object: 'object',
  };
  static DATETYPE = {
    date: 'date',
    datetime: 'datetime',
  };

  static KEYBOARDEVENTS = {
    ArrowDown: 'ArrowDown',
    ArrowUp: 'ArrowUp',
    Enter: 'Enter',
  };
  static OPERATORS  = {
    EQUAL: 'equal',
    IN: 'in',
    CONTAINS: 'contains',
    DECIMALSEARCH: 'decimalsearch',
    BETWEEN: 'between',
    OR: 'or',
    ARRAY: 'array',
  };
  static INFO_CATEGORIES = {
    PAYMENT_MESSAGE: 'PAYMENT_MESSAGE',
    BANK_STATUS: 'BANK_STATUS',
    ACCOUNT_STATEMENTS: 'ACCOUNT_STATEMENTS',
    PAYMENT_ITEM: 'PAYMENT_ITEM',
    ACCOUNTING_DOCUMENT: 'ACCOUNTING_DOCUMENT',
    COLLECTION_ORDER: 'COLLECTION_ORDER',
    COLLECTION_MASTER_DATA: 'COLLECTION_MASTER_DATA',
    COLLECTION_AGENCY_FEEDBACK_STATUS: 'COLLECTION_AGENCY_FEEDBACK_STATUS',
    STATEMENT_MESSAGE: 'STATEMENT_MESSAGE',
    DIRECT_DEBIT_MESSAGE: 'DIRECT_DEBIT_MESSAGE',
    DISTRIBUTED_SIGNATURE: 'DISTRIBUTED_SIGNATURE',
    INTRADAY_STATEMENTS: 'INTRADAY_STATEMENTS',
    OTHERS: 'OTHERS',
    ALL: 'ALL',
    CASHFLOW: 'CASHFLOW',
    POSTMAN: 'POSTMAN',
    STATEMENT_REPORT: 'STATEMENT_REPORT',
  };

  static MODES = {
    create: 'create',
    view: 'view',
    edit: 'edit',
    copy: 'copy',
  };
  static BATCH = {
    BANK: 'bank',
    NONBANK: 'nonBank',
    ERP: 'ERP',
    EBICS: 'EBICS',
    ADAPTERTYPE: 'adapterType=',
    NON_BANK :'non-bank',
  };

  static LOCALES = {
    en_EN: 'en-EN',
    es_ES: 'es-ES',
  };

  static LANGUAGE_CODES = {
    en: 'en-US',
    de: 'de-DE'
  }

  static CURRENCYCODES = {
    EUR: 'EUR',
    USD: 'USD',
  };

  static FX_RATES_FILE_IMPORT_TYPES = {
    ECB: 'ECB',
  };

  static PAYMENT_ITEMS_STATUS = {
    100: 'Approved',
    200: 'Rejected',
    300: 'Sent',
    400: 'New',
    500: 'Import Successful',
    600: 'Partially Accepted by My bank',
    700: 'Accepted by Counter Party',
    800: 'Accepted by My bank',
    900: 'Execution Date Changed',
    1000: 'Item Deleted',
    1100: 'Item Removed',
  };

  static PAYMENT_MESSAGES_STATUS = {
    100: 'Approved',
    200: 'Rejected',
    300: 'Sent',
    400: 'New',
    500: 'Import Successful',
    600: 'Partially Accepted by My bank',
    700: 'Accepted by Counter Party',
    800: 'Accepted by My bank',
    900: 'Transmitted',
    1000: 'Delivered',
    1100: 'Execution Date Changed',
    1200: 'Item Deleted',
    1300: 'Item Removed',
    1400: 'Initialized',
    1500: 'Initialization Failed',
    1600: 'NEW',
    1700: 'Active',
    1800: 'Transmission Failed',
  };

  static JOB_STATUS = {
    STARTED: 'STARTED',
    PLANNED: 'PLANNED',
    COMPLETED: 'COMPLETED',
  };

  static ACTIONS = {
    DELETE: 'delete',
    BULK_DELETE: 'bulk_delete',
    EDIT: 'edit',
    VIEW: 'view',
    APPROVE: 'approve',
    REJECT: 'reject',
    SEND: 'send',
    COPY: 'copy',
    PAYMENT_ITEMS: 'payment items',
    CHANGE_EXECUTION_DATE: 'Change Execution Date',
    REGENERATE_PAYMENT: 'Regenerate Payment',
    REMOVE: 'remove',
    GENERATE_PAYMENT: 'generate payment',
    ACCEPT_DUPLICATE: 'accept_duplicate',
    ACCOUNT_STATEMENTS: 'ACCOUNT_STATEMENTS',
    REMOVE_DUPLICATE: 'remove_duplicate_item',
    RETRY: 'retry',
    NEXT_EXECUTION: 'next_execution',
    JOB_LOGS: 'job_logs',
    PASSWORD_INCORRECT: 'PASSWORD_INCORRECT',
    PASSWORD_LOCKED: 'PASSWORD_LOCKED',
    EBICS: 'EBICS',
    TRANSMISSON_FAIL: 'TRANSMISSION FAILED',
    MOVE_TO_TOP: 'MOVE_TO_TOP',
    MOVE_TO_BOTTOM: 'MOVE_TO_BOTTOM',
    CANCEL: 'Cancel',
    LOGS: 'Logs',
    DOWNLOAD: 'Download',
    VIEW_CERTIFICATE: 'VIEW_CERTIFICATE',
    DOWNLOAD_KEY_LETTERS: 'DOWNLOAD_KEY_LETTERS',
    SET_AS_DEFAULT_USER: 'SET_AS_DEFAULT_USER',
    DELETE_USER: 'DELETE_USER'
  };

  static ARE_LOV = {
    valueNameField: 'valueNameFieldColumnDropdownArr',
    valueKeyField: 'valueKeyFieldColumnDropdownArr',
  };

  static STEP_FUNCTIONS = {
    READ_SFTP: 'READ_SFTP',
    READ_ALEVATE_PAYMENTS: 'READ_ALEVATE_PAYMENTS',
    READ_SMARTEYE: 'READ_SMARTEYE',
    RECEIVE_FUNCTION: 'RECEIVE_FUNCTION',
    SEND_FUNCTION: 'SEND_FUNCTION',
    GENERATE_PAYMENT: 'GENERATE_PAYMENTS',
    FX_RATE_IMPORT_FROM_API: 'FX_RATE_IMPORT_FROM_API',
    DISTRIBUTED_SIGNATURE: 'DISTRIBUTED_SIGNATURE',
    EXECUTE_RECIPE: 'EXECUTE_RECIPE',
    TRIGGER_ENGAGEMENT: 'TRIGGER_ENGAGEMENT',
    TRIGGER_OVERDUE_DAYS: 'TRIGGER_OVERDUE_DAYS',
    GENERATE_DIRECT_DEBIT: 'GENERATE_DIRECT_DEBIT',
    SPLIT_STATEMENTS : 'SPLIT_STATEMENTS'
  };

  static WIDGET_STATE = {
    COLLAPSED: 'collapsed',
    EXPANDED: 'expanded',
  };

  static DOWNLOAD_ACTION_ITEMS = [
    {
      id: 'export_pdf',
      text: 'Export to PDF',
    },
    {
      id: 'export_excel',
      text: 'Export to Excel',
    },
  ];

  static SORT_DIRECTION = {
    ASCENDING: 'Ascending',
    DESCENDING: 'Descending',
  };

  static DISTRIBUTION_CRITERIA = {
    STAGE1: 'Stage 1 Scoring',
    STAGE2: 'Stage 2 Number of engagements',
  };

  static USAGE_INFO = {
    role: 'role',
    application: 'application',
  };

  static PLATFORM_USER_TYPES = {
    context: {
      SYSTEM: 'SYSTEM',
      OPERATOR: 'OPERATOR',
      CUSTOMER: 'CUSTOMER',
    },
    level: {
      ADMIN: 'ADMIN',
      USER: 'USER',
      SINGLE_LEVEL_USER: 'SINGLE_LEVEL_USER',
    },
    USER_TYPES: {
      SYSTEM_ADMIN: 'SYSTEM_ADMIN',
      SYSTEM_USER: 'SYSTEM_USER',
      OPERATOR_ADMIN: 'OPERATOR_ADMIN',
      OPERATOR_USER: 'OPERATOR_USER',
      CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
      CUSTOMER_USER: 'CUSTOMER_USER',
      CUSTOMER_SINGLE_LEVEL_USER: 'CUSTOMER_SINGLE_LEVEL_USER',
    },
    USER_TYPES_ALIAS: {
      SYSTEM_ADMIN: 'System Administrator',
      SYSTEM_USER: 'System User',
      OPERATOR_ADMIN: 'Operator Administrator',
      OPERATOR_USER: 'Operator User',
      CUSTOMER_ADMIN: 'Tenant Administrator',
      CUSTOMER_USER: 'Tenant User',
      CUSTOMER_SINGLE_LEVEL_USER: 'Tenant Single-level User',
    },
    KEYS: {
      TENANT: 'tenant',
    },
  };
  static PLATFORM_ROLE_TYPES = {
    SYSTEM_ADMIN: 'SYSTEM_ADMIN',
    SYSTEM_USER: 'SYSTEM_USER',
    OPERATOR_ADMIN: 'OPERATOR_ADMIN',
    OPERATOR_USER: 'OPERATOR_USER',
    CUSTOMER_ADMIN: 'CUSTOMER_ADMIN',
    CUSTOMER_USER: 'CUSTOMER_USER',
    CUSTOMER_USER_SINGLE_LEVEL: 'CUSTOMER_USER_SINGLE_LEVEL',
  };

  static CONFIGURATION_PACK_ACTIONS = {
    VIEW: 'View',
    EDIT: 'Edit',
    COPY: 'Copy',
    DELETE: 'Delete',
    DOWNLOAD: 'Download',
    APPLY: 'Apply',
    FINALIZE_PACK: 'Finalize Pack',
  };

  static CONFIGURATION_PACKAGE = {
    ENTITIES: {
      CURRENCY: 'currency',
    },
    SERVICES: {
      MASTER_DATA: 'masterdata',
    },
  };

  static DATA_TYPE = {
    OBJECT: 'object',
    STRING: 'string',
  };

  static DATA_ENDPOINT = {
    DEVICE_2FA: 'device2Fa',
  };

  static SESSION_EXPIRED = {
    EVENT_TYPE: 'token_refresh_error',
    MUTATION_TYPE: 'childList',
  };

  static TENANT_PREPARATION = {
    CONTEXT: 'context',
    CONTEXT_CUSTOMER: 'CUSTOMER',
    LEVEL: 'level',
    LEVEL_ADMIN: 'ADMIN',
    LEVEL_SINGLE_LEVEL_USER: 'SINGLE_LEVEL_USER',
    STATUS: 'status',
    STATUS_PREPARATION: 'IN_PREPARATION',
    SESSION_KEY: 'tenantStatus',
    TENANT_ID: 'tenantId',
  };

  static FEATURE_TOGGLE = {
    NEW_APPROVAL_WORKFLOW: 'common.frontendNavigation.newApprovalWorkflow',
    SERRALA_MOBILE_MFA: 'common.frontendNavigation.serralaMobileMFA',
    CASH_APP_MENU: 'common.frontendNavigation.cashAppMenu'
  };

  static MOBILE_MFA = {
    MOBILE_APP: 'mobileApp',
    EXTERNAL_AUTHENTICATOR: 'externalAuthenticator'
  };

  static CDN = {
    URL: 'CDN-URL',
    TOKEN: 'CDN-TOKEN',
    LOGO_CONTAINER: 'logo',
    VERSION: '1.0.5',
  }
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum PACKAGE_TYPE {
  GLOBAL = 'GLOBAL',
  LOCAL = 'LOCAL',
  TEMPLATE = 'TEMPLATE',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum DASHBOARD_SUMMARY {
  DASHBOARD_1 = 'dashboard1',
  DASHBOARD_2 = 'dashboard2',
  COLLECTIONS_DASHBOARD = 'collectionsDashboard',
  COLLECTIONS_DASHBOARD_2 = 'collectionsDashboard2',
  CASH_MANAGEMENT_DASHBOARD = 'cashManagementDashboard',
  OPERATOR_DASHBOARD = 'operatorDashboard',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum EVENT_TYPES {
  powerPanelPinned = 'powerPanelPinned',
  globalNavbarPinned = 'globalNavbarPinned',
  paymentFileChanged = 'paymentFileChanged',
  addToAccountChange = 'addToAccount',
  connectorChange = 'connectorChange',
  jobDefinitionChange = 'jobDefinitionChange',
  hideLayout = 'HIDE_LAYOUT',
  toggleBreadcrumb = 'TOGGLE_BREADCRUMB',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum FileStatus {
  APPROVE = 100,
  REJECT = 200,
  SEND = 300,
  DELETE = 400,
  CHANGE_EXECUTION_DATE = 500,
  REGENERATE_PAYMENT = 600,
  REMOVE = 700,
  EDIT = 800,
  DUPLICATE = 900,
  RETRY = 1000,
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum FileStatusString {
  APPROVE = '100',
  REJECT = '200',
  SEND = '300',
  IMPORT_SUCCESFULL = '400',
  NEW = '600',
  EXECUTION_DATE_CHANGED = '900',
  ITEM_DELETED = '1000',
  ITEM_REMOVED = '1100',
  ACCEPTED_BY_MY_BANK = 'ACCP',
  ACCEPTED_BY_COUNTER_PARTY = 'ACCPCP',
  PARTIALLY_ACCEPTED_BY_COUNTER_PARTY = 'PARTCP',
  PARTIALLY_ACCEPTED_BY_MY_BANK = 'PART',
  REJECTED_BY_COUNTER_PARTY = 'RJCTCP',
  REJECTED_BY_MY_BANK = 'RJCT',
  DELIVERED_TO_MY_BANK = 'DLVRD',
  TRANSMITTED = 'TRSMT',
  GENERATION_FAILED = '700',
  ERROR_IN_IMPORT = '500',
  DUPLICATE = '800',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const FileStatusCodeValue = {
  '100': 'Approved',
  '200': 'Rejected',
  '300': 'Sent',
  '400': 'Import Successful',
  '500': 'Import Failed',
  '600': 'New',
  '700': 'Generation Failed',
  '800': 'Duplicate',
  '900': 'Execution Date Changed',
  '1000': 'Item Deleted',
  '1100': 'Item Removed',
  ACCP: 'Accepted by My bank',
  ACCPCP: 'Accepted by Counter Party',
  PARTCP: 'Partially Accepted by Counterparty',
  PART: 'Partially Accepted by My bank',
  RJCTCP: 'Rejected by Counter Party',
  RJCT: 'Rejected by My bank',
  DLVRD: 'Delivered to my bank',
  TRSMT: 'Transmitted',
  FTRSMT: 'Transmission Failed',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum FileStatusValue {
  APPROVE = 'Approved',
  REJECT = 'Rejected',
  SEND = 'Sent',
  IMPORT_SUCCESFULL = 'Import Successful',
  NEW = 'New',
  EXECUTION_DATE_CHANGED = 'Execution Date Changed',
  ITEM_REMOVED = 'Item Removed',
  ITEM_DELETED = 'Item Deleted',
  ACCEPTED_BY_MY_BANK = 'Accepted by My bank',
  PARTIALLY_ACCEPTED_BY_COUNTER_PARTY = 'Partially Accepted by Counterparty',
  PARTIALLY_ACCEPTED_BY_MY_BANK = 'Partially Accepted by My bank',
  ACCEPTED_BY_COUNTER_PARTY = 'Accepted by Counter Party',
  REJECTED_BY_MY_BANK = 'Rejected by My bank',
  REJECTED_BY_COUNTER_PARTY = 'Rejected by Counter Party',
  DELIVERED_TO_MY_BANK = 'Delivered to my bank',
  TRANSMITTED = 'Transmitted',
  GENERATION_FAILED = 'Generation Failed',
  ERROR_IN_IMPORT = 'Import Failed',
  DUPLICATE = 'Duplicate',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum FILTER_MODE {
  IMMEDIATE = 'Immediate',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum NAME_TITLES {
  MR = 'Mr',
  MRS = 'Mrs',
  MS = 'Ms',
  DR = 'Dr',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const NAME_TITLES_DROPDOWN = [
  { value: 'mr', text: 'SHARED.nameTitles.mr' },
  { value: 'ms', text: 'SHARED.nameTitles.ms' },
  { value: 'mrs', text: 'SHARED.nameTitles.mrs' },
  { value: 'dr', text: 'SHARED.nameTitles.dr' },
];

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum CONTACT_TYPE {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
  WHATSAPP = 'Whatsapp',
  FAX = 'Fax',
  OTHER = 'Other',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum LOV_TYPES {
  ACCOUNT_TYPE = 'Account TYPE',
  SEGMENT = 'Segment',
  REGION = 'Region',
  FX_RATE_TYPE = 'FX Rate Type',
  FX_RATE_FILE_FORMAT = 'FXRateFileFormat',
  CASHFLOW = 'Cashflow',
  ERP_TYPE = 'ERP Type',
  ENTITY = 'Entity',
  ATTRIBUTES = 'Attributes',
  PAYMENT_MESSAGE = 'PaymentMessageStatus',
  STATEMENT_MESSAGE = 'StatementMessageStatus',
  COLLECTION = 'GdStatus',
  BANK_STATUS = 'MatchStatus',
  PAYMENT_FORMAT = 'PaymentFormat',
  FILE_DELIMITER_INBOUND = 'FileDelimiterInbound',
  FILE_DELIMITER_OUTBOUND = 'FileDelimiterOutbound',
  DATA_LOAD_TYPE = 'Data_Load_Type',
  LINK_DRIVER_TYPE = 'Link_Driver_Type',
  POSTMAN = 'POSTMAN',
  POSTMANSTATUS = 'POSTMANSTATUS',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum LOV_KEY {
  PAYMENT_MESSAGE = '100',
  COLLECTION = '100',
  BANK_STATUS = '100',
  STATEMENT_MESSAGE = '100',
  STATEMENT_MESSAGE_FOR_SPLIT = '501',
}

export const LOV_KEY_SPLIT_SEND  = ["501","506"];

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const CONTACT_TYPE_DROPDOWN = [
  { value: 'Primary', text: 'SHARED.contactTypes.primary' },
  { value: 'Secondary', text: 'SHARED.contactTypes.secondary' },
  { value: 'Whatsapp', text: 'SHARED.contactTypes.whatsapp' },
  { value: 'Fax', text: 'SHARED.contactTypes.fax' },
  { value: 'Other', text: 'SHARED.contactTypes.other' },
];

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum EMAIL_TYPE {
  PRIMARY = 'Primary',
  SECONDARY = 'Secondary',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const EMAIL_TYPE_DROPDOWN = [
  { value: 'Primary', text: 'SHARED.emailTypes.primary' },
  { value: 'Secondary', text: 'SHARED.emailTypes.secondary' },
];

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum AccountNumberCodeType {
  IBAN = 'IBAN',
  ACCOUNT_NUMBER = 'ACCOUNT_NUMBER',
  ALTERNATE_ACCOUNT_NUMBER = 'ALTERNATE_ACCOUNT_NUMBER',
  CUSTOMER_NUMBER = 'CUSTOMER_NUMBER',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum ChartOrientation {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum AnomalyCells {
  PartnerName = 'cdtrnm',
  Currency = 'amtccy',
  Amount = 'instdamt',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const PRODUCT_TYPE = {
  ALL_PRODUCTS: 'AllProducts',
  CASH_MANAGEMENT: 'CashManagement',
  COLLECTION_MANAGEMENT: 'CollectionsManagement',
  ARE_STATUS: 'Payments',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const PRODUCT_TYPE_LIST = [
  {
    key: PRODUCT_TYPE.ALL_PRODUCTS,
    uiLabel: 'MASTERDATA.listOfValues.allProducts',
  },
  {
    key: PRODUCT_TYPE.CASH_MANAGEMENT,
    uiLabel: 'MASTERDATA.listOfValues.cashManagement',
  },
  {
    key: PRODUCT_TYPE.COLLECTION_MANAGEMENT,
    uiLabel: 'MASTERDATA.listOfValues.collectionManagement',
  },
  {
    key: PRODUCT_TYPE.ARE_STATUS,
    uiLabel: 'MASTERDATA.listOfValues.payments',
  },
];

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum APPLICATION {
  MASTERDATA = 'masterdata',
  COLLECTION = 'Collections',
  ARESTATUS = 'ARE Status',
  PAYMENTS = 'Payments',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const ARESTATUSLIST = {
  statusList: [{ statusCode: 'SUCCESS' }, { statusCode: 'ERROR' }],
  entityList: [{ entity: 'Payment Item' }, { entity: 'Statement Item' },{ entity: 'Statement' }],
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum ENCODING_TYPE {
  ASCII = 'ASCII',
  UTF8 = 'UTF-8',
}
/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const DATE_FORMAT = 'dd/MM/yyyy';

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const DATE_TIME_FORMAT = 'dd/MM/yyyy HH:mm';

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const DATE_FULL_TIME_FORMAT = 'dd/MM/yyyy HH:mm:ss';

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum HTTP_RESPONSE_CODES {
  SUCCESS = 200,
  SUCCESS_ADD_PARTIALLY = 207,
  UNAUTHORIZED = 401,
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const BIC_CODE_TYPES = {
  BIC8: 'BIC8',
  BIC11: 'BIC11',
  DN: 'DN',
  GERMAN_BLZ_CODE: 'BLZ',
};
export const BIC_TYPE_FIELDS =['bic8', 'bic11', 'dn', 'bankCode'];

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum CASHFLOW_LOV {
  AMOUNT = 'amount',
  AMOUNT_LABEL = 'Amount',
}
/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const ORDINAL_SUFFIX = {
  '1': 'First',
  '2': 'Second',
  '3': 'Third',
  '4': 'Fourth',
  '5': 'Fifth',
  '6': 'Sixth',
  '7': 'Seventh',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum IMPORT_TYPE {
  MANUAL = 'Manual',
  SCHEDULER = 'Scheduler',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const CONDITION = {
  and: '$and',
  or: '$or',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const FIELDTYPE = {
  STRING: 'string',
  NUMBER: 'number',
  DROPDOWN: 'dropdown',
  AMOUNT: 'amount',
  BOOLEAN: 'boolean',
  DATE: 'date',
  DATETIME: 'datetime',
  ARRAY: 'array',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const USER_TYPE = {
  GROUP: 'group',
  USER: 'USER',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const APPROVAL_ACTIONS = {
  APPROVE: 'APPROVAL_APPROVE',
  REJECT: 'APPROVAL_REJECT',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const APPROVAL_ACTION_STATUS = {
  APPROVE: '61',
  REJECT: '62',
};

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum AccountNumberType {
  AccountNumber = 'ACCOUNT_NUMBER',
  AlternateAccountNumber = 'ALTERNATE_ACCOUNT_NUMBER',
  Iban = 'IBAN',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum ResponseStatus {
  SUCCESS = 200,
  CREATED = 201,
  BAD_REQUEST = 400,
  INTERNAL_SERVER_ERROR = 500,
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum EntityType {
  CUSTOMER = 'Customer',
  COMPANY = 'Company',
  COUNTRY = 'Country',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum DistributionKeys {
  AMOUNT_CLASSES = 'Amount Classes',
  CLAIM_AGE = 'Claim Age',
  REGIONAL_AFFILIATION = 'Regional Affiliation',
  CITY = 'City',
  COUNTRY = 'Country',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum ConfigType {
  INBOUND = 'Inbound',
  OUTBOUND = 'Outbound',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum IbanFormatColumn {
  CUSTOMERLISTCOLNAME = 'IBAN',
  ACCOUNTLISTCOLNAME = 'IBAN_Number',
  ACCOUNTKEY = 'accountKey',
  UNKNOWNACCOUNTCOLNAME = 'accountNumber',
  ACTIONNAME = 'filter',
  FILTERING = 'filtering',
  SEARCHACTION = 'searching',
  FILTERACTION = 'filtering',
  CLEARACTION = 'clearFilter',
}
export const enum ImasterBankColumn {
  BANKCODE = 'bankCode',
  BIC_CODE = 'bic_code',
  ARRAYOPRATOR = 'array',
  VALUEFIELD = 'value',
  BANK_CODE_TYPE = 'bank_code_type',
}
export const enum IpaymentPartnerColumn {
  CONTACTNAME = 'contactName',
  EMAIL = 'email',
  CONTACTNAMEFIELD = 'partnerContact.contactName',
  EMAILFILED = 'partnerContact.email.mailId',
}
export const enum DataType {
  BOOLEAN = 'boolean',
  STRING = 'string',
  NUMBER = 'number',
  DATE = 'date',
  DATE_SEARCH = 'datesearch',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const enum ADDITIONAL_FIELD {
  SDDIDENTIFICATION = 'SDD Identification',
  SDDMANDATEID = 'SDD Mandate ID',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export const FIELD_NAME = {
  ACTIVE: 'active',
  VALUE: 'value',
  KEY_DESC: 'keyDesc',
  LOCAL_CODE_DESC: 'localCodeDescription',
  LANGUAGE: 'language',
};

export const OPERATOR = {
  CONTAINS: 'contains'
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum RECORD_TYPE {
  DD = 'DD',
  PY = 'PY',
}
export enum RECORD_DESCRIPTION {
  DM = 'Direct Debit Message',
  PM = 'Payment Message',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum FILE_CONSTANTS {
  XML = 'xml',
  FAILED = 'Failed',
  SUCCESS = 'Success',
  FILE = 'file',
  PARAM = 'param',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum FILE_ENTITIES {
  SERVICENAME = 'masterdata',
  CURRENCY = 'currency',
  COUNTRY = 'country',
  COMPANY = 'company',
  MASTERBANK = 'masterBank',
  BANK = 'bank',
  ACCOUNT = 'account',
  PARTNER = 'partner',
  BTFMAPPING = 'btfMapping',
  COMMUNICATION_CHANNEL = 'communicationchannel',
  CONNECTOR = 'connector',
  OUTBOUND_CONNECTOR_DETER = 'outboundConnectorDetermination',
}

/**
 * @deprecated Please don't use this class anymore, all new constants should be put into @alevate/serrala-common/constants
 */
export enum migrationProgressbarColor {
  white = '#FFF',
}
export enum configPumpTool {
  addConfigurationPack = 'addConfigurationPack',
}

export const CATEGORY_LIST = [
  { key: 'PAYMENT_ITEM', value: 'Payment Item' },
  { key: 'STATEMENT_ITEM', value: 'Statement Item' },
  { key: 'STATEMENT', value: 'Statement' }
];
