/* eslint-disable @typescript-eslint/naming-convention */
import { ROUTER_LINKS_FULL_PATH } from '@app/core/constants';

export enum SUBMODULES_LEVEL {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
}

export const MODULE_ID = {
  home: 1000,
  tenant: 1500,
  tenantList: 1700,
  dashboard: 2000,
  worklist: 3000,
  payments: 3100,
  paymentFiles: 3101,
  paymentItems: 3102,
  bankStatements: 3103,
  bankStatus: 3104,
  others: 3106,
  accountStatements: 3107,
  accountStatementMessages: 3108,
  statementItems: 3109,
  tenantDetails: 3157,
  controlCenter: 3110,
  unknownAccounts: 3111,
  certificateManagement: 3113,
  btfMapping: 3116,
  auditLogs: 3150,
  gdpr: 3156,
  cashApp: 3200,
  cashAllocation: 3201,
  fileTracker: 3202,
  contentTracker: 3203,
  processingReport: 3204,
  cashAppPayments: 3205,
  openItemCoreData: 3206,
  cashAppSettings: 3210,
  accountingSystems: 3211,
  origins: 3212,
  toleranceGroups: 3213,
  reasonCodes: 3214,
  paymentTerms: 3215,
  dataPurgeOpenItems: 3216,
  cashAppRulebook: 3220,
  rulebookFilters: 3221,
  postingLines: 3222,
  postingRules: 3223,
  postingRuleSequences: 3224,
  businessPartnerEnrichment: 3225,
  filterValueConversion: 3226,
  conditions: 3227,
  setRestrictions: 3228,
  accountsPayable: 3301,
  invoiceMonitor: 3302,
  invoiceAllocation: 3303,
  posting: 3304,
  approvals: 3305,
  collectionsManagement: 3400,
  accountingDocumentMessages: 3401,
  accountingDocumentEntries: 3402,
  externalCollectionEngagementStatus: 3403,
  externalCollectionsFeedbackMessages: 3404,
  accountingDocumentCollectionFeedbackMessages: 3405,
  cashManagement: 3500,
  cashflows: 3501,
  cashPositionWorksheet: 3502,
  cashflowFiles: 3504,
  unprocessedCashflows: 3505,
  creditLines: 3503,
  reports: 4000,
  bookmarks: 5000,
  controlPanel: 6000,
  masterdata: 6100,
  externalServiceProvider: 6101,
  customers: 6102,
  countryMasterData: 6103,
  currencyMasterData: 6104,
  masterBankMasterData: 6105,
  companyMasterData: 6106,
  accounts: 6107,
  cashflowTypeMasterData: 6108,
  externalSystem: 6109,
  paymentPartner: 6110,
  collectionStatus: 6111,
  listOfValues: 6120,
  fxRates: 6121,
  bankMasterData: 6122,
  cflevelsMasterData: 6123,
  profile: 7000,
  settings: 8000,
  statusMessageSettings: 8101,
  userManamgent: 8102,
  featureManagement: 8103,
  tenantManagement: 8106,
  operatorManagement: 8105,
  userAuthorization: 8107,
  connectorSettings: 8111,
  duplicateCheckSetup: 8112,
  cashflowTypeAssignment: 8113,
  approvalWorkflow: 8114,
  jobScheduler: 8115,
  outBoundConnector: 8116,
  externalCollectionsSetup: 9000,
  okCriteria: 9101,
  aiDecisionEngine: 9102,
  paymentFormatIdentification: 9103,
  areConfig: 9104,
  are: 9105,
  areMonitoring: 9107,
  cashflowEnhancements: 9106,
  directDebitMessages: 3117,
  directDebitItems: 3118,
  intraDayMessages: 9300,
  intraDayStatements: 9301,
  configurationPackManagement: 8108,
  liqudityTypesMasterData: 6124,
  migration: 7007,
  customerMatchingCriteria: 8120,
  importLogging: 10000,
  messageImportOverview: 10100,
  postman: 10500,
};

export const DEFAULT_MENU_PERMISSIONS = [
  1000, 1500, 1700, 3000, 3100, 3106, 3200, 3210, 3220, 3400, 4000, 5000, 6000, 6100, 7000, 8000,
  9000, 3500, 3400, 10000, 10100,
];

export const MENU_PERMISSION = 'OPEN';

export const MENU_PERMISSION_MAP = {
  dashboard: 2000,
  'payment-message': 3101,
  'payment-item': 3102,
  'bank-status-message': 3104,
  'account-statement': 3107,
  'account-statement-message': 3108,
  'account-statement-items': 3109,
  'control-center': 3110,
  'unknown-account': 3111,
  'ebics-btf-mapping': 3116,
  'audit-log': 3150,
  'collection-agencies': 6101,
  customers: 6102,
  country: 6103,
  currency: 6104,
  company: 6106,
  'master-bank': 6105,
  account: 6107,
  'cashflow-type': 6108,
  'external-systems': 6109,
  'payment-partner': 6110,
  'collection-status': 6111,
  'list-of-values': 6120,
  'fx-rate': 6121,
  'my-bank': 6122,
  'cashflow-level': 6123,
  'bank-status-message-settings': 8101,
  'user-manager': 8102,
  'feature-management': 8103,
  'tenant-management': 8106,
  'operator-management': 8105,
  'policy-service': 8107,
  'connector-settings': 8111,
  'duplicate-check-payment-items': 8112,
  'cashflow-type-assignment': 8113,
  'approval-workflow': 8114,
  batch: 8115,
  'outbound-connector-determination': 8116,
  'external-collections-setup-direct-allocation': 9101,
  'external-collections-setup-ai-decision-engine': 9102,
  'payment-format-identification': 9103,
  'accounting-document-messages': 3401,
  'accounting-document-entries': 3402,
  'external-collections-engagement-status': 3403,
  'external-collections-feedback-messages': 3404,
  'accounting-document-collection-feedback-messages': 3405,
  'intraday-messages': 3108,
  'intraday-statements': 3107,
  cashflows: 3501,
  cashPositionWorksheet: 3502,
  'cashflow-files': 3504,
  'unprocessed-cashflows': 3505,
  'cashflow-enhancements': 9106,
  'gdpr-personal-data': 3156,
  'alevate-rule-engine-config': 9104,
  'alevate-rule-engine': 9105,
  'monitoring-logs': 9107,
  'direct-debit-message': 3117,
  'direct-debit-item': 3118,
  'configuration-package': 8108,
  'liquidity-type': 6124,
  'customer-matching-configuration': 8120,
  'tenant-details': 3157,
  migration: 7007,
  postman: 10500,
  cashApp: 3200,
  'cash-allocation-bank-statement': 3201,  
  'file-tracker': 3202,
  'content-tracker': 3203,
  'processing-protocol': 3204,
  'isas': 3205,
  'open-item-core-data': 3206,
  CashAppSettings: 3210,
  'accounting-system-core-data': 3211,
  'origin-core-data': 3212,
  'tolerance-group':3213,
  'amount-type': 3214,
  'payment-condition': 3215,
  'open-item-import-settings': 3216,
  CashAppRulebook: 3220,
  'fine-filter': 3221,
  'posting-rule': 3222,
  'profile-manager': 3223,
  'profile-sequence': 3224,
  'business-partner': 3225,
  'replacement': 3226,
  'conditions': 3227,
  'restrictions': 3228,
};

const Home = {
  key: 'home',
  text: 'CORE.menu.home',
  link: ROUTER_LINKS_FULL_PATH.home,
  icon: 'se-home',
  moduleId: MODULE_ID.home,
  canLandingPage: false,
  isActive: false,
};

const TenantList = {
  key: 'tenantList',
  text: 'CORE.menu.tenantList',
  link: '',
  icon: '',
  moduleId: MODULE_ID.tenantList,
  submenu: [],
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  canLandingPage: false,
};

const Tenant = {
  key: 'tenant',
  text: 'CORE.menu.tenant',
  link: '',
  icon: '',
  image: '/images/tenant.svg',
  moduleId: MODULE_ID.tenant,
  submenu: [TenantList],
  isSubmenu: true,
  canLandingPage: false,
  isActive: false,
};

/**
 * Start of Payments Sub modules
 */
const PaymentsFiles = {
  key: 'paymentFiles',
  text: 'CORE.menu.paymentFiles',
  link: ROUTER_LINKS_FULL_PATH.paymentFiles,
  icon: '',
  moduleId: MODULE_ID.paymentFiles,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const PaymentsItems = {
  key: 'paymentItems',
  text: 'CORE.menu.paymentItems',
  link: ROUTER_LINKS_FULL_PATH.paymentItems,
  icon: '',
  moduleId: MODULE_ID.paymentItems,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const BankStatus = {
  key: 'bankStatus',
  text: 'CORE.menu.bankStatus',
  link: ROUTER_LINKS_FULL_PATH.bankStatus,
  icon: '',
  moduleId: MODULE_ID.bankStatus,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const AccountStatements = {
  key: 'accountStatements',
  text: 'CORE.menu.accountStatements',
  link: ROUTER_LINKS_FULL_PATH.accountStatements,
  icon: '',
  moduleId: MODULE_ID.accountStatements,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const AccountStatementMessages = {
  key: 'accountStatementMessages',
  text: 'CORE.menu.accountStatementMessages',
  link: ROUTER_LINKS_FULL_PATH.accountStatementMessages,
  icon: '',
  moduleId: MODULE_ID.accountStatementMessages,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const StatementItems = {
  key: 'statementItems',
  text: 'CORE.menu.statementItems',
  link: ROUTER_LINKS_FULL_PATH.statementItems,
  icon: '',
  moduleId: MODULE_ID.statementItems,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const ControlCenter = {
  key: 'controlCenter',
  text: 'CORE.menu.controlCenter',
  link: ROUTER_LINKS_FULL_PATH.controlCenter,
  icon: '',
  moduleId: MODULE_ID.controlCenter,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
// @TODO
// Added patch of ASM Module ID to ISM Module ID;
// need to update later once backend has policy permission for these both.
const IntraDayMessages = {
  key: 'IntraDayMessages',
  text: 'CORE.menu.intradayMessages',
  link: ROUTER_LINKS_FULL_PATH.intraDayMessages,
  icon: '',
  moduleId: MODULE_ID.accountStatementMessages,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const intraDayStatements = {
  key: 'intraDayStatements',
  text: 'CORE.menu.intradayStatements',
  link: ROUTER_LINKS_FULL_PATH.intraDayStatements,
  icon: '',
  moduleId: MODULE_ID.accountStatements,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const unknownAccounts = {
  key: 'unknownAccounts',
  text: 'CORE.menu.unknownAccounts',
  link: ROUTER_LINKS_FULL_PATH.unknownAccounts,
  icon: '',
  moduleId: MODULE_ID.unknownAccounts,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const certificateManagement = {
  key: 'certificateManagement',
  text: 'CORE.menu.certificateManagement',
  link: ROUTER_LINKS_FULL_PATH.certificateManagement,
  icon: '',
  moduleId: MODULE_ID.certificateManagement,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const btfMapping = {
  key: 'btfMapping',
  text: 'CORE.menu.btfMapping',
  link: ROUTER_LINKS_FULL_PATH.btfMapping,
  icon: '',
  moduleId: MODULE_ID.btfMapping,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const auditLogs = {
  key: 'auditLogs',
  text: 'CORE.menu.auditLogs',
  link: ROUTER_LINKS_FULL_PATH.audit,
  icon: '',
  moduleId: MODULE_ID.auditLogs,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const gdpr = {
  key: 'gdpr',
  text: 'CORE.menu.gdpr',
  link: ROUTER_LINKS_FULL_PATH.gdpr,
  icon: '',
  moduleId: MODULE_ID.gdpr,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const are_menu = {
  key: 'Alevate RuleEngine',
  text: 'CORE.menu.alevateRuleEngine',
  link: ROUTER_LINKS_FULL_PATH.recipe,
  icon: '',
  moduleId: MODULE_ID.are,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const are_monitoring = {
  key: 'AREMonitor',
  text: 'CORE.menu.RuleEngineLog',
  link: ROUTER_LINKS_FULL_PATH.MonitoringLog,
  icon: '',
  moduleId: MODULE_ID.areMonitoring,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const customerMatchingCriteria = {
  key: 'customerMatchingCriteria',
  text: 'CORE.menu.customerMatchingCriteria',
  link: ROUTER_LINKS_FULL_PATH.customerMatchingCriteria,
  icon: '',
  moduleId: MODULE_ID.customerMatchingCriteria,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const DirectDebitMessages = {
  key: 'directDebitMessages',
  text: 'CORE.menu.directDebitMessages',
  link: ROUTER_LINKS_FULL_PATH.directDebitMessages,
  icon: '',
  moduleId: MODULE_ID.directDebitMessages,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const DirectDebitItems = {
  key: 'directDebitItems',
  text: 'CORE.menu.directDebitItems',
  link: ROUTER_LINKS_FULL_PATH.directDebitItems,
  icon: '',
  moduleId: MODULE_ID.directDebitItems,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const Postman = {
  key: 'postman',
  text: 'CORE.menu.postman',
  link: ROUTER_LINKS_FULL_PATH.postman,
  icon: '',
  moduleId: MODULE_ID.postman,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

/**
 * End of Payments Sub modules
 */

// Start of Worklist sub modules
const Payments = {
  key: 'payments',
  text: 'CORE.menu.payments',
  link: ROUTER_LINKS_FULL_PATH.payments,
  icon: '',
  moduleId: MODULE_ID.payments,
  submenu: [
    PaymentsFiles,
    PaymentsItems,
    DirectDebitMessages,
    DirectDebitItems,
    BankStatus,
    AccountStatementMessages,
    AccountStatements,
    StatementItems,
    ControlCenter,
    IntraDayMessages,
    intraDayStatements,
    Postman,
  ],
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  canLandingPage: false,
};

const cashAllocation = {
  key: 'cash-allocation-bank-statement',
  text: 'CORE.menu.cashAllocation',
  link: ROUTER_LINKS_FULL_PATH.cashAllocation,
  icon: '',
  moduleId: MODULE_ID.cashAllocation,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const fileTracker = {
  key: 'file-tracker',
  text: 'CORE.menu.fileTracker',
  link: ROUTER_LINKS_FULL_PATH.cashAppFileTracker,
  icon: '',
  moduleId: MODULE_ID.fileTracker,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const contentTracker = {
  key: 'content-tracker',
  text: 'CORE.menu.contentTracker',
  link: ROUTER_LINKS_FULL_PATH.cashAppContentTracker,
  icon: '',
  moduleId: MODULE_ID.contentTracker,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const processingReport = {
  key: 'processing-protocol',
  text: 'CORE.menu.processingReport',
  link: ROUTER_LINKS_FULL_PATH.cashAppProcessingReport,
  icon: '',
  moduleId: MODULE_ID.processingReport,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const cashAppPayments = {
  key: 'isas',
  text: 'CORE.menu.payments',
  link: ROUTER_LINKS_FULL_PATH.cashAppPayments,
  icon: '',
  moduleId: MODULE_ID.cashAppPayments,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const openItems = {
  key: 'open-item-core-data',
  text: 'CORE.menu.openItems',
  link: ROUTER_LINKS_FULL_PATH.cashAppOpenItems,
  icon: '',
  moduleId: MODULE_ID.openItemCoreData,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const CashApp = {
  key: 'cashApp',
  text: 'CORE.menu.cashApp',
  link: ROUTER_LINKS_FULL_PATH.cashApp,
  icon: '',
  moduleId: MODULE_ID.cashApp,
  submenu: [
    cashAllocation,
    fileTracker,
    contentTracker,
    processingReport,
    cashAppPayments,
    openItems,
  ],
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  canLandingPage: false,
};

const AccountingSystems = {
  key: 'accounting-system-core-data',
  text: 'CORE.menu.accountingSystems',
  link: ROUTER_LINKS_FULL_PATH.accountingSystems,
  icon: '',
  moduleId: MODULE_ID.accountingSystems,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const Origins = {
  key: 'origin-core-data',
  text: 'CORE.menu.origins',
  link: ROUTER_LINKS_FULL_PATH.origins,
  icon: '',
  moduleId: MODULE_ID.origins,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const ToleranceGroups = {
  key: 'tolerance-group',
  text: 'CORE.menu.toleranceGroups',
  link: ROUTER_LINKS_FULL_PATH.toleranceGroups,
  icon: '',
  moduleId: MODULE_ID.toleranceGroups,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const ReasonCodes = {
  key: 'amount-type',
  text: 'CORE.menu.reasonCodes',
  link: ROUTER_LINKS_FULL_PATH.reasonCodes,
  icon: '',
  moduleId: MODULE_ID.reasonCodes,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const PaymentTerms = {
  key: 'payment-condition',
  text: 'CORE.menu.paymentTerms',
  link: ROUTER_LINKS_FULL_PATH.paymentTerms,
  icon: '',
  moduleId: MODULE_ID.paymentTerms,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const DataPurgeOpenItems = {
  key: 'open-item-import-settings',
  text: 'CORE.menu.dataPurgeOpenItems',
  link: ROUTER_LINKS_FULL_PATH.dataPurgeOpenItems,
  icon: '',
  moduleId: MODULE_ID.dataPurgeOpenItems,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const CashAppSettings = {
  key: 'cashAppSettings',
  text: 'CORE.menu.cashAppSettings',
  link: ROUTER_LINKS_FULL_PATH.cashApp,
  icon: '',
  moduleId: MODULE_ID.cashAppSettings,
  submenu: [
    AccountingSystems,
    Origins,
    ToleranceGroups,
    ReasonCodes,
    PaymentTerms,
    DataPurgeOpenItems,
  ],
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  canLandingPage: false,
};

const RulebookFilters = {
  key: 'fine-filter',
  text: 'CORE.menu.rulebookFilters',
  link: ROUTER_LINKS_FULL_PATH.rulebookFilters,
  icon: '',
  moduleId: MODULE_ID.rulebookFilters,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const PostingLines = {
  key: 'posting-rule',
  text: 'CORE.menu.postingLines',
  link: ROUTER_LINKS_FULL_PATH.postingLines,
  icon: '',
  moduleId: MODULE_ID.postingLines,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const PostingRules = {
  key: 'profile-manager',
  text: 'CORE.menu.postingRules',
  link: ROUTER_LINKS_FULL_PATH.postingRules,
  icon: '',
  moduleId: MODULE_ID.postingRules,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const PostingRuleSequences = {
  key: 'profile-sequence',
  text: 'CORE.menu.postingRuleSequences',
  link: ROUTER_LINKS_FULL_PATH.postingRuleSequences,
  icon: '',
  moduleId: MODULE_ID.postingRuleSequences,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const BusinessPartnerEnrichment = {
  key: 'business-partner',
  text: 'CORE.menu.businessPartnerEnrichment',
  link: ROUTER_LINKS_FULL_PATH.businessPartnerEnrichment,
  icon: '',
  moduleId: MODULE_ID.businessPartnerEnrichment,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const FilterValueConversion = {
  key: 'replacement',
  text: 'CORE.menu.filterValueConversion',
  link: ROUTER_LINKS_FULL_PATH.filterValueConversion,
  icon: '',
  moduleId: MODULE_ID.filterValueConversion,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const Conditions = {
  key: 'conditions',
  text: 'CORE.menu.conditions',
  link: ROUTER_LINKS_FULL_PATH.conditions,
  icon: '',
  moduleId: MODULE_ID.conditions,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const SetRestrictions = {
  key: 'restrictions',
  text: 'CORE.menu.setRestrictions',
  link: ROUTER_LINKS_FULL_PATH.setRestrictions,
  icon: '',
  moduleId: MODULE_ID.setRestrictions,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const CashAppRulebook = {
  key: 'cashAppRulebook',
  text: 'CORE.menu.cashAppRulebook',
  link: ROUTER_LINKS_FULL_PATH.cashApp,
  icon: '',
  moduleId: MODULE_ID.cashApp,
  submenu: [
    RulebookFilters,
    PostingLines,
    PostingRules,
    PostingRuleSequences,
    BusinessPartnerEnrichment,
    FilterValueConversion,
    Conditions,
    SetRestrictions
  ],
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  canLandingPage: false,
};

const invoiceMonitor = {
  key: 'accountPayableInvoiceMonitor',
  text: 'CORE.menu.invoiceMonitor',
  link: ROUTER_LINKS_FULL_PATH.accountPayableInvoiceMonitor,
  icon: '',
  moduleId: MODULE_ID.invoiceMonitor,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const invoiceAllocation = {
  key: 'invoiceAllocation',
  text: 'CORE.menu.invoiceAllocation',
  link: ROUTER_LINKS_FULL_PATH.accountPayableInvoiceAllocation,
  icon: '',
  moduleId: MODULE_ID.invoiceAllocation,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const posting = {
  key: 'accountPayablePosting',
  text: 'CORE.menu.posting',
  link: ROUTER_LINKS_FULL_PATH.accountPayablePosting,
  icon: '',
  moduleId: MODULE_ID.posting,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const approvals = {
  key: 'accountPayableApprovals',
  text: 'CORE.menu.approvals',
  link: ROUTER_LINKS_FULL_PATH.accountPayableApprovals,
  icon: '',
  moduleId: MODULE_ID.approvals,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const AccountsPayable = {
  key: 'accountsPayable',
  text: 'CORE.menu.accountsPayable',
  link: ROUTER_LINKS_FULL_PATH.accountPayable,
  icon: '',
  moduleId: MODULE_ID.accountsPayable,
  submenu: [invoiceMonitor, invoiceAllocation, posting, approvals],
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  canLandingPage: false,
};

/**
 * Start of Cash Management sub modules
 */

const UnprocessedCashflows = {
  key: 'unprocessedCashflows',
  text: 'CORE.menu.unprocessedCashflows',
  link: ROUTER_LINKS_FULL_PATH.unprocessedCashflows,
  icon: '',
  moduleId: MODULE_ID.unprocessedCashflows,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const CashflowFiles = {
  key: 'cashflowFiles',
  text: 'CORE.menu.cashflowFiles',
  link: ROUTER_LINKS_FULL_PATH.cashflowFiles,
  icon: '',
  moduleId: MODULE_ID.cashflowFiles,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const Cashflows = {
  key: 'cashflows',
  text: 'CORE.menu.cashflows',
  link: ROUTER_LINKS_FULL_PATH.cashflows,
  icon: '',
  moduleId: MODULE_ID.cashflows,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const CashPositionWorksheet = {
  key: 'cashPositionWorksheet',
  text: 'CORE.menu.cashPositionWorksheet',
  link: ROUTER_LINKS_FULL_PATH.cashPositionWorksheet,
  icon: '',
  moduleId: MODULE_ID.cashPositionWorksheet,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const CreditLines = {
  key: 'creditLines',
  text: 'CORE.menu.creditLines',
  link: ROUTER_LINKS_FULL_PATH.creditLines,
  icon: '',
  moduleId: MODULE_ID.creditLines,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

/**
 * End of Cash Management sub modules
 */

const CashManagement = {
  key: 'cashManagement',
  text: 'CORE.menu.cashManagement',
  link: ROUTER_LINKS_FULL_PATH.cashManagement,
  icon: '',
  moduleId: MODULE_ID.cashManagement,
  submenu: [
    CashflowFiles,
    UnprocessedCashflows,
    Cashflows,
    CashPositionWorksheet,
  ],
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  canLandingPage: false,
};
/**
 * End of Cash Management Modules
 */

/**
 * Start of Collections Management Sub modules
 */
const accountingDocumentMessages = {
  key: 'accountingDocumentMessages',
  text: 'CORE.menu.accountingDocumentMessages',
  link: ROUTER_LINKS_FULL_PATH.accountingDocumentMessages,
  icon: '',
  moduleId: MODULE_ID.accountingDocumentMessages,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const accountingDocumentEntries = {
  key: 'accountingDocumentEntries',
  text: 'CORE.menu.accountingDocumentEntries',
  link: ROUTER_LINKS_FULL_PATH.accountingDocumentEntries,
  icon: '',
  moduleId: MODULE_ID.accountingDocumentEntries,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const externalCollectionEngagementStatus = {
  key: 'externalCollectionEngagementStatus',
  text: 'CORE.menu.externalCollectionEngagementStatus',
  link: ROUTER_LINKS_FULL_PATH.externalCollectionEngagementStatus,
  icon: '',
  moduleId: MODULE_ID.externalCollectionEngagementStatus,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const externalCollectionsFeedbackMessages = {
  key: 'externalCollectionsFeedbackMessages',
  text: 'CORE.menu.externalCollectionsFeedbackMessages',
  link: ROUTER_LINKS_FULL_PATH.externalCollectionsFeedbackMessages,
  icon: '',
  moduleId: MODULE_ID.externalCollectionsFeedbackMessages,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const accountingDocumentCollectionFeedbackMessages = {
  key: 'accountingDocumentCollectionFeedbackMessages',
  text: 'CORE.menu.accountingDocumentCollectionFeedbackMessages',
  link: ROUTER_LINKS_FULL_PATH.accountingDocumentCollectionFeedbackMessages,
  icon: '',
  moduleId: MODULE_ID.accountingDocumentCollectionFeedbackMessages,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

/**
 * End of Collections Management Sub modules
 */

const CollectionsManagement = {
  key: 'collectionsManagement',
  text: 'CORE.menu.collectionsManagement',
  link: ROUTER_LINKS_FULL_PATH.collectionsManagement,
  icon: '',
  moduleId: MODULE_ID.collectionsManagement,
  submenu: [
    accountingDocumentMessages,
    accountingDocumentEntries,
    externalCollectionsFeedbackMessages,
    externalCollectionEngagementStatus,
    accountingDocumentCollectionFeedbackMessages,
  ],
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  canLandingPage: false,
};

/**
 * End of Cash Management Modules
 */

// Items under Activities
const Worklist = {
  key: 'worklist',
  text: 'CORE.menu.worklist',
  link: ROUTER_LINKS_FULL_PATH.worklist,
  icon: 'se-worklist',
  moduleId: MODULE_ID.worklist,
  submenu: [
    Payments,
    CashManagement,
    CollectionsManagement,
    CashApp,
    // AccountsPayable,
  ],
  isSubmenu: true,
  canLandingPage: false,
  isActive: true,
};

const Reports = {
  key: 'reports',
  text: 'CORE.menu.reports',
  // 'link': ROUTER_LINKS_FULL_PATH.reports,
  link: '/address',
  icon: 'se-reports',
  moduleId: MODULE_ID.reports,
  canLandingPage: false,
};

const Bookmarks = {
  key: 'bookmarks',
  text: 'CORE.menu.bookmarks',
  link: ROUTER_LINKS_FULL_PATH.bookmarks,
  icon: 'se-bookmark',
  moduleId: MODULE_ID.bookmarks,
  isSubmenu: true,
  canLandingPage: false,
};

export const MENU_CONFIG = [Home, Tenant, Worklist]; //Reports, Bookmarks

const TenantDetails = {
  key: 'tenantDetails',
  text: 'CORE.menu.tenantDetails',
  link: ROUTER_LINKS_FULL_PATH.tenantDetails,
  icon: 'se-tenant-details-icon',
  moduleId: MODULE_ID.tenantDetails,
  isSubMenu: false,
  canLandingPage: true,
};

const Profile = {
  text: 'CORE.menu.profileDetails',
  link: ROUTER_LINKS_FULL_PATH.profile,
  icon: 'se-check-circle-fill',
  profilePic: '/images/profile.png',
  moduleId: MODULE_ID.profile,
  isSubmenu: false,
  canLandingPage: true,
};

const Logout = {
  text: 'CORE.menu.logout',
  link: ROUTER_LINKS_FULL_PATH.logout,
  icon: 'se-log-out',
  isLogout: true,
};

/**
 * Start of MasterData Sub modules
 */
const ExternalServiceProvider = {
  key: 'externalServiceProvider',
  text: 'CORE.menu.externalServiceProvider',
  link: ROUTER_LINKS_FULL_PATH.externalServiceProvider,
  icon: '',
  moduleId: MODULE_ID.externalServiceProvider,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const Customers = {
  key: 'customer',
  text: 'CORE.menu.customer',
  link: ROUTER_LINKS_FULL_PATH.customer,
  icon: '',
  moduleId: MODULE_ID.customers,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const CountryMasterData = {
  key: 'countryMasterData',
  text: 'CORE.menu.country',
  link: ROUTER_LINKS_FULL_PATH.countryMasterData,
  icon: '',
  moduleId: MODULE_ID.countryMasterData,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const CurrencyMasterData = {
  key: 'currencyMasterData',
  text: 'CORE.menu.currency',
  link: ROUTER_LINKS_FULL_PATH.currencyMasterData,
  icon: '',
  moduleId: MODULE_ID.currencyMasterData,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const AccountMasterData = {
  key: 'Accounts',
  text: 'CORE.menu.accounts',
  link: ROUTER_LINKS_FULL_PATH.accountMasterData,
  icon: '',
  moduleId: MODULE_ID.accounts,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const masterBankMasterData = {
  key: 'masterBankMasterData',
  text: 'CORE.menu.masterBank',
  link: ROUTER_LINKS_FULL_PATH.masterBankMasterData,
  icon: '',
  moduleId: MODULE_ID.masterBankMasterData,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const companyMasterData = {
  key: 'companyMasterData',
  text: 'CORE.menu.companies',
  link: ROUTER_LINKS_FULL_PATH.companyMasterData,
  icon: '',
  moduleId: MODULE_ID.companyMasterData,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const cashflowTypeMasterData = {
  key: 'cashflowTypeMasterData',
  text: 'CORE.menu.cashflowTypes',
  link: ROUTER_LINKS_FULL_PATH.cashflowtypeMasterData,
  icon: '',
  moduleId: MODULE_ID.cashflowTypeMasterData,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const ExternalSystem = {
  key: 'externalSystem',
  text: 'CORE.menu.externalSystem',
  link: ROUTER_LINKS_FULL_PATH.externalSystem,
  icon: '',
  moduleId: MODULE_ID.externalSystem,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const collectionStatus = {
  key: 'collectionStatus',
  text: 'CORE.menu.collectionStatus',
  link: ROUTER_LINKS_FULL_PATH.collectionStatus,
  icon: '',
  moduleId: MODULE_ID.collectionStatus,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const ListOfValues = {
  key: 'listOfValues',
  text: 'CORE.menu.listOfValues',
  link: ROUTER_LINKS_FULL_PATH.listOfValues,
  icon: '',
  moduleId: MODULE_ID.listOfValues,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const fxRates = {
  key: 'fxRates',
  text: 'CORE.menu.fxRates',
  link: ROUTER_LINKS_FULL_PATH.fxRatesMasterData,
  icon: '',
  moduleId: MODULE_ID.fxRates,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const paymentPartner = {
  key: 'paymentPartner',
  text: 'CORE.menu.paymentPartner',
  link: ROUTER_LINKS_FULL_PATH.paymentPartner,
  icon: '',
  moduleId: MODULE_ID.paymentPartner,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const bankMasterData = {
  key: 'bank',
  text: 'CORE.menu.bank',
  link: ROUTER_LINKS_FULL_PATH.bank,
  icon: '',
  moduleId: MODULE_ID.bankMasterData,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const cflevelsMasterData = {
  key: 'cf-levels',
  text: 'CORE.menu.cflevels',
  link: ROUTER_LINKS_FULL_PATH.cflevelsMasterData,
  icon: '',
  moduleId: MODULE_ID.cflevelsMasterData,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const liqudityTypesMasterData = {
  key: 'liqudity-types',
  text: 'CORE.menu.liqudityTypes',
  link: ROUTER_LINKS_FULL_PATH.liqudityTypesMasterData,
  icon: '',
  moduleId: MODULE_ID.liqudityTypesMasterData,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

/**
 * End of MasterData Sub modules
 */

/**
 * Start of Control Panel Sub modules
 */
const MasterData = {
  key: 'masterdata',
  text: 'CORE.menu.masterdata',
  link: ROUTER_LINKS_FULL_PATH.masterdata,
  icon: '',
  moduleId: MODULE_ID.masterdata,
  submenu: [
    CountryMasterData,
    CurrencyMasterData,
    masterBankMasterData,
    bankMasterData,
    companyMasterData,
    paymentPartner,
    Customers,
    ExternalServiceProvider,
    ExternalSystem,
    collectionStatus,
    AccountMasterData,
    cashflowTypeMasterData,
    liqudityTypesMasterData,
    cflevelsMasterData,
    fxRates,
    ListOfValues,
  ],
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  canLandingPage: false,
};

const StatusMessageSettings = {
  key: 'statusMessageSettings',
  text: 'CORE.menu.statusMessageSettings',
  icon: '',
  link: ROUTER_LINKS_FULL_PATH.statusMessageSettings,
  moduleId: MODULE_ID.statusMessageSettings,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const CPwebMigration = {
  key: 'migration',
  text: 'CORE.menu.migration',
  icon: '',
  link: ROUTER_LINKS_FULL_PATH.migration,
  moduleId: MODULE_ID.migration,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const UserManagement = {
  key: 'userManagement',
  text: 'CORE.menu.userManagement',
  link: ROUTER_LINKS_FULL_PATH.userManagement,
  icon: '',
  moduleId: MODULE_ID.userManamgent,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const FeatureManagement = {
  key: 'featureManagement',
  text: 'CORE.menu.featureManagement',
  link: ROUTER_LINKS_FULL_PATH.featureManagement,
  icon: '',
  moduleId: MODULE_ID.featureManagement,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const TenantManagement = {
  key: 'tenantManagement',
  text: 'CORE.menu.tenantManagement',
  link: ROUTER_LINKS_FULL_PATH.tenantManagement,
  icon: '',
  moduleId: MODULE_ID.tenantManagement,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const OperatorManagement = {
  key: 'operatorManagement',
  text: 'CORE.menu.operatorManagement',
  link: ROUTER_LINKS_FULL_PATH.operatorManagement,
  icon: '',
  moduleId: MODULE_ID.operatorManagement,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const ConfigurationPackManagement = {
  key: 'configurationPackManagement',
  text: 'CORE.menu.configurationPackManagement',
  link: ROUTER_LINKS_FULL_PATH.configurationPackManagement,
  icon: '',
  moduleId: MODULE_ID.configurationPackManagement,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const UserAuthorization = {
  key: 'userAuthorization',
  text: 'CORE.menu.userAuthorization',
  link: ROUTER_LINKS_FULL_PATH.userAuthorization,
  icon: '',
  moduleId: MODULE_ID.userAuthorization,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const ConnectorSettings = {
  key: 'connectorSettings',
  text: 'CORE.menu.connectorSettings',
  link: ROUTER_LINKS_FULL_PATH.connectorSettings,
  icon: '',
  moduleId: MODULE_ID.connectorSettings,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};
const outBoundConnector = {
  key: 'outBoundConnector',
  text: 'CORE.menu.outBoundConnector',
  link: ROUTER_LINKS_FULL_PATH.outBoundConnector,
  icon: '',
  moduleId: MODULE_ID.outBoundConnector,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const DuplicateCheckSetup = {
  key: 'duplicateCheckSetup',
  text: 'CORE.menu.duplicateCheckSetup',
  link: ROUTER_LINKS_FULL_PATH.duplicateCheckSetup,
  icon: '',
  moduleId: MODULE_ID.duplicateCheckSetup,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const CashflowTypeAssignment = {
  key: 'cashflowTypeAssignment',
  text: 'CORE.menu.cashflowTypeAssignment',
  link: ROUTER_LINKS_FULL_PATH.cashflowTypeAssignment,
  icon: '',
  moduleId: MODULE_ID.cashflowTypeAssignment,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const ApprovalWorkflow = {
  key: 'approvalWorkflow',
  text: 'CORE.menu.approvalWorkflow',
  link: ROUTER_LINKS_FULL_PATH.approvalWorkflow,
  icon: '',
  moduleId: MODULE_ID.approvalWorkflow,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const JobScheduler = {
  key: 'jobScheduler',
  text: 'CORE.menu.jobScheduler',
  link: ROUTER_LINKS_FULL_PATH.jobScheduler,
  icon: '',
  moduleId: MODULE_ID.jobScheduler,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const OkCriteria = {
  key: 'okCriteria',
  text: 'CORE.menu.okCriteria',
  link: ROUTER_LINKS_FULL_PATH.okCriteria,
  icon: '',
  moduleId: MODULE_ID.okCriteria,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const AIDecisionEngine = {
  key: 'aiDecisionEngine',
  text: 'CORE.menu.aiDecisionEngine',
  link: ROUTER_LINKS_FULL_PATH.aiDecisionEngine,
  icon: '',
  moduleId: MODULE_ID.aiDecisionEngine,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const ExternalCollectionsSetup = {
  key: 'externalCollectionsSetup',
  text: 'CORE.menu.externalCollectionsSetup',
  moduleId: MODULE_ID.externalCollectionsSetup,
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  submenu: [OkCriteria, AIDecisionEngine],
  isSubmenu: true,
  canLandingPage: false,
};

const PaymentFormatIdentification = {
  key: 'paymentFormatIdentification',
  text: 'CORE.menu.paymentFormatIdentification',
  link: ROUTER_LINKS_FULL_PATH.formatIdendification,
  icon: '',
  moduleId: MODULE_ID.paymentFormatIdentification,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const AREConfig = {
  key: 'AREConfig',
  text: 'CORE.menu.areConfig',
  link: ROUTER_LINKS_FULL_PATH.areConfigList,
  icon: '',
  moduleId: MODULE_ID.areConfig,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: false,
};

const CashflowEnhancements = {
  key: 'cashflowEnhancements',
  text: 'CORE.menu.cashflowEnhancements',
  link: ROUTER_LINKS_FULL_PATH.cashflowEnhancements,
  icon: '',
  moduleId: MODULE_ID.cashflowEnhancements,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const SettingMenu = {
  key: 'settings',
  text: 'CORE.menu.settings',
  moduleId: MODULE_ID.settings,
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  submenu: [
    StatusMessageSettings,
    UserManagement,
    UserAuthorization,
    ConnectorSettings,
    outBoundConnector,
    JobScheduler,
    DuplicateCheckSetup,
    CashflowTypeAssignment,
    ApprovalWorkflow,
    PaymentFormatIdentification,
    AREConfig,
    FeatureManagement,
    TenantManagement,
    OperatorManagement,
    ConfigurationPackManagement,
    CashflowEnhancements,
    CPwebMigration,
    customerMatchingCriteria,
  ],
  isSubmenu: true,
  canLandingPage: false,
};

const MessageImportOverview = {
  key: 'messageImportOverview',
  text: 'CORE.menu.messageImportOverview',
  link: ROUTER_LINKS_FULL_PATH.messageImportOverview,
  icon: '',
  moduleId: MODULE_ID.messageImportOverview,
  subModuleLevel: SUBMODULES_LEVEL.TWO,
  canLandingPage: true,
};

const ImportLogging = {
  key: 'importLogging',
  text: 'CORE.menu.importLogging',
  moduleId: MODULE_ID.importLogging,
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  submenu: [MessageImportOverview],
  isSubmenu: true,
  canLandingPage: false,
};

const othersMenu = {
  key: 'others',
  text: 'CORE.menu.others',
  moduleId: MODULE_ID.others,
  subModuleLevel: SUBMODULES_LEVEL.ONE,
  submenu: [
    unknownAccounts,
    btfMapping,
    auditLogs,
    gdpr,
    are_monitoring,
    are_menu,
  ], //certificateManagement
  isSubmenu: true,
  canLandingPage: false,
};

/**
 * End of Control Panel Sub modules
 */

const ControlPanel = {
  key: 'controlPanel',
  text: 'CORE.menu.controlPanel',
  icon: 'se-control-panel',
  moduleId: MODULE_ID.controlPanel,
  submenu: [
    MasterData,
    SettingMenu,
    ImportLogging,
    ExternalCollectionsSetup,
    othersMenu,
    CashAppSettings,
    CashAppRulebook,
  ],
  isSubmenu: true,
  canLandingPage: false,
};

export const BOTTOM_MENU_CONFIG = [ControlPanel, Profile, Logout];

export const BOTTOM_MENU_WHICH_REQUIRE_PERMISSION = [TenantDetails];

/**
 * End of Cash Management sub modules
 */
