import { SerralaToasterComponent } from '@alevate/serrala-toaster';
import { CoreService } from './../../core/services/core.service';
import {
  Component,
  EventEmitter,
  OnInit,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { SidebarComponent as SyncSidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { MenuService } from './menu/menu.service';
import { LayoutSettingsService } from './layout-settings.service';
import { AuthenticationService } from '@alevate/serrala-auth-lib';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Constants, ROUTER_LINKS_FULL_PATH } from '@app/core/constants';
import { NavigationEnd, Router } from '@angular/router';
import { Tenant } from '@app/core/models/tenant';
import { SUBMODULES_LEVEL } from './menu/menu.config';
import { OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { TenantDetails } from '@app/generated/tenant-service/model/tenantDetails';
import { HttpParams } from '@angular/common/http';
import { CdnService } from '@app/core/services/cdn.service';
import { map } from 'rxjs/operators';
import { Subscription, BehaviorSubject } from 'rxjs';
import { FeatureToggleService } from '@alevate/serrala-common/services';
import { environment } from '@environments/environment';

interface RedirectParam {
  url: [string];
  params: any;
}

import { AutoUnsubscribe } from '@alevate/serrala-common/decorators';

@AutoUnsubscribe
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService, LayoutSettingsService],
})
export class SidebarComponent implements OnInit, OnDestroy {
  @ViewChild('sidebarMenuInstance')
  sidebar: SyncSidebarComponent;
  @Output('toggledSidebar')
  toggledSidebarEvent: EventEmitter<any> = new EventEmitter<any>();

  width = 'auto';
  submenuWidth = 'auto';
  asideContainerWidth = 200;
  dockSize = '50px';
  powerPanelCollapsedWidth = 50;
  collapsedMenuWidth = 50;
  mediaQuery = '(min-width: 600px)';
  enableDock = true;
  asideContainerPinned: boolean;
  menuItems: any[];
  bottomMenuItems: any[];
  layoutSettings: { onHoverExpandMenu: any; isOnClickSubMenu: any };
  isSubmenuActive: boolean;
  isSubmenuHover: boolean;
  activeLink: any;
  isMenuHover: boolean;
  appDetails: any;
  submenuDetails: any;
  currentSubmenuItems: any;
  searchFilter: string;
  containerHeight: number;
  menuHeight = 0;
  menuColumns = 1;
  username = '';
  tenant!: number;
  isTenantList: boolean;
  tenants: TenantDetails[] = [];
  @ViewChild('toast') toast: SerralaToasterComponent;
  isLoggedInUserOperator: boolean;
  searchText: string;
  filterVal: string;
  tenantLogoError: boolean[] = [];
  private cachedTenantLogos: string[] = [];
  private envUrl: string | null;
  private token: any;
  private featureToggleSubscription: Subscription;
  private isCashAppMenuAvailable: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    public menuService: MenuService,
    private layoutSettingsService: LayoutSettingsService,
    public authService: AuthenticationService,
    private translateService: TranslateService,
    private router: Router,
    private coreService: CoreService,
    private cdnService: CdnService,
    private featureToggleService: FeatureToggleService
  ) {

    translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
          this.searchText = translateService.instant('CORE.powerpanel.search');
      },
    });
    this.getMenuItems();
    const layoutSettings = this.layoutSettingsService.getLayoutSettings();
    this.layoutSettings = {
      onHoverExpandMenu: layoutSettings.hoverExpand,
      isOnClickSubMenu: layoutSettings.clickSubMenu,
    };
    //call tenant list
    this.username = this.authService.getUserName();

    this.onTenantSwitch();
  }

  onTenantSwitch(): void {
    document.addEventListener('tenantSwitch', (event: CustomEvent) => {
      const { redirectUrl, tenantId, tenantName } = event.detail;
      const stringifiedHttpParams = new HttpParams({
        fromObject: redirectUrl.params,
      }).toString();

      this.switchTenant(
        { key: tenantId, text: tenantName },
        `${redirectUrl.url[0]}?${stringifiedHttpParams}`
      );
    });
  }
  
  /**
   * Handles errors when loading tenant logos.
   *
   * @param {number} [index] - The index of the tenant logo to mark as an error.
   */
  handleLogoError(index?) {
    this.tenantLogoError[index] = true;
  }

  /**
   * Get the URL for a tenant's logo based on the provided key or generate a new one.
   *
   * @param {number} key - The unique identifier for the tenant.
   * @param {number} index - The index of the tenant in the list.
   * @param {number} listLength - The total number of tenants in the list.
   * @returns {string} The URL of the tenant's logo.
   */
  getTenantsLogo(key: number, index: number, listLength: number) {
    if (this.isSubmenuActive && index < listLength) {
      const cachedLogoEntry = this.cachedTenantLogos.find(item => item.startsWith(`${key}:`));
      
      if (cachedLogoEntry) {
        const [cachedKey, cachedUrl] = cachedLogoEntry.split(': ');

        return cachedUrl;
      } else {
        const noCaching = `?ver=${Math.random().toString(36).substr(2, 5)}`;
        const logoUrl = `${this.envUrl}/${Constants.CDN.LOGO_CONTAINER}/${key}_tiny.jpg${noCaching}&${this.token.cdnToken}`;
  
        this.cachedTenantLogos.push(`${key}: ${logoUrl}`);
  
        return logoUrl;
      }
    }
  }

  /**
   * Calls the service to fetch permitted menu items if they are not stored in the session
   * storage.
   */
  getMenuItems(): void {
    if (!sessionStorage.getItem('permittedMenuItems')) {
      this.menuService.getMenuPermissionsFromServer().subscribe({
        next: (response) => {
          this.isCashAppMenuAvailable.subscribe( {
            next: (value) => {
            this.menuService.setPermittedMenuIds(response, value);
            this.setMenuItems();
            },
          });
        },
      });
    } else {
      this.setMenuItems();
    }
  }

  setCurrentActiveLink() {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        switch (event.urlAfterRedirects) {
          case ROUTER_LINKS_FULL_PATH['dashboardSummary']:
          case ROUTER_LINKS_FULL_PATH['dashboard1']:
          case ROUTER_LINKS_FULL_PATH['dashboard2']:
            this.resetIsActiveLinkState();
            const index = this.menuItems.findIndex(
              (menuItem) => menuItem.key === 'home'
            );
            if (index > -1) {
              this.menuItems[index]['isActive'] = true;
            }
            break;

          case ROUTER_LINKS_FULL_PATH['reports']:
            this.resetIsActiveLinkState();
            const menuIndex = this.menuItems.findIndex(
              (menuItem) => menuItem.key === 'reports'
            );
            if (menuIndex > -1) {
              this.menuItems[menuIndex]['isActive'] = true;
            }
            break;

          default:
            this.updateWorklistAsActive();
            break;
        }

        this.setControlPanelActive(event.urlAfterRedirects);
      }
    });
  }

  /**
   * To Set Control panel active
   *
   * @param {string} url
   * @memberof SidebarComponent
   */
  setControlPanelActive(url: string): void {
    const masterDataUrl = `${ROUTER_LINKS_FULL_PATH.masterdata}/`;
    const settingsUrl = `${ROUTER_LINKS_FULL_PATH.settings}/`;
    // If Master Data string available within url then set Control Panel as active state
    if (url.search(masterDataUrl) !== -1 || url.search(settingsUrl) !== -1) {
      this.resetIsActiveLinkState();
      const bottomMenuIndex = this.bottomMenuItems.findIndex(
        (menuItem) => menuItem.key === 'controlPanel'
      );
      if (bottomMenuIndex > -1) {
        this.bottomMenuItems[bottomMenuIndex]['isActive'] = true;
      }
    }
  }

  ngOnInit() {
    this.appDetails = this.layoutSettingsService.getAppSetting();
    this.translateService.get('CORE.powerpanel.search').subscribe({
      next: (data) => {
        this.searchText = data;
      },
    });
    this.tenantInPreparation();
    this.envUrl = sessionStorage.getItem(Constants.CDN.URL);
    this.token = this.cdnService.getToken();
    this.getFeatureToggle();

    const data = fetch(environment.featureToggleUrl);
    data.then((res) =>res.json()).then(features => { 
      this.menuService.disabledFeatures = features;
      this.setMenuItems(true); 
    });
  }

  ngOnDestroy(): void {
    this.cachedTenantLogos = [];
    this.envUrl = null;
    this.token = null;
    if (this.featureToggleSubscription) {
      this.featureToggleSubscription.unsubscribe();
    }
  }

  /**
   *  Create Submenu
   *
   * @param {*} event
   * @memberof SidebarComponent
   */
  onCreated(): void {
    if (this.sidebar.element.classList.contains('e-hidden')) {
      this.sidebar.element.classList.remove('e-hidden');
      this.containerHeight = document.getElementById('submenu').clientHeight;
      this.sidebar.hide();
    }
  }

  hideSidebar() {
    this.isMenuHover = false;
    setTimeout(() => {
      if (
        !(
          this.isMenuHover ||
          this.asideContainerPinned ||
          this.isSubmenuActive ||
          this.isSubmenuHover
        )
      ) {
        this.sidebar.hide();
        this.hideSubmenu('submenu');
      }
    }, 100);
  }
  
  hideSidebarAndSubMenu() {
    this.isMenuHover = false;
    setTimeout(() => {
        this.sidebar.hide();
        this.clearSubmenu('submenu')
    }, 500);
  }


  showSidebar() {
    this.isMenuHover = true;
    this.sidebar.show();
  }

  /**
   * Set Menu Active
   *
   * @returns void
   * @param {{ isActive: boolean }} menuObj
   * @memberof SidebarComponent
   */
  updateMenuActiveStatus(menuObj: { isActive: boolean }): void {
    this.resetIsActiveLinkState();
    menuObj.isActive = true;
  }

  updateWorklistAsActive() {
    this.resetIsActiveLinkState();
    const worklistIndex = this.menuItems.findIndex(
      (menuItem) => menuItem.key === 'worklist'
    );
    this.menuItems[worklistIndex].isActive = true;
  }

  resetIsActiveLinkState() {
    const index = this.menuItems.findIndex(
      (menuItem) => menuItem.isActive === true
    );
    if (index > -1) {
      this.menuItems[index]['isActive'] = false;
    } else {
      const bottomActiveIndex = this.bottomMenuItems.findIndex(
        (menuItem) => menuItem.isActive === true
      );
      if (bottomActiveIndex > -1) {
        this.bottomMenuItems[bottomActiveIndex]['isActive'] = false;
      }
    }
  }

  pinNavbar() {
    this.asideContainerPinned = !this.asideContainerPinned;
    if (!this.asideContainerPinned) {
      this.sidebar.hide();
    } else {
      this.sidebar.show();
    }
    this.toggledSidebarEvent.emit(this.asideContainerPinned);
  }

  /**
   *
   *
   * @param {string} panelId
   * @param {string} activeLink
   * @param {*} submenuDetails
   * @memberof SidebarComponent
   */

  showSubmenu(panelId: string, activeLink: string, submenuDetails: any): void {
    this.menuHeight = 0;
    this.isTenantList = false;
    const clientWidth = this.asideContainerWidth;
    this.isSubmenuActive = true;
    this.activeLink = activeLink;
    this.submenuDetails = submenuDetails;
    this.currentSubmenuItems = Object.assign([], this.submenuDetails.submenu);
    setTimeout(() => {
      if (this.sidebar.isOpen) {
        document.getElementById(panelId).style.marginLeft = clientWidth + 'px';
        document.getElementById(panelId).style.width = this.submenuWidth;
        document.getElementById(panelId).classList.add('open');
        this.calculateColumnsMenu();
      }
    }, 600);
  }

  /**
   *
   * To hide Panel
   *
   * @param {string} panelId
   * @memberof SidebarComponent
   */
  hideSubmenu(panelId: string) {
    setTimeout(() => {
      if (!this.isSubmenuHover || !this.isSubmenuActive) {
        this.clearSubmenu(panelId);
      }
    }, 200);
  }

  /**
   *
   * Clear Submenu
   *
   * @param {string} panelId
   * @memberof SidebarComponent
   */
  clearSubmenu(panelId: string): void {
    this.activeLink = '';
    this.isSubmenuActive = false;
    this.resetSubmenuWidth(panelId);
  }

  /**
   *
   * Set Active to submenu
   *
   * @param {booelan} isActive
   * @param {string} panelId
   * @memberof SidebarComponent
   */
  activeSubmenu(isActive: boolean, panelId: string): void {
    this.isSubmenuActive = isActive ? true : false;
    if (!isActive) {
      this.isSubmenuHover = false;
      // setTimeout(() => {
      if (!this.isSubmenuActive || !this.isSubmenuHover) {
        this.clearSubmenu(panelId);
      }
      // }, 500)
      this.filterVal = '';
    } else {
      this.isSubmenuHover = true;
      this.sidebar.show();
    }
  }

  /**
   *
   * Reset Submenu Width
   *
   * @param {string} panelId
   * @memberof SidebarComponent
   */
  resetSubmenuWidth(panelId: string): void {
    document.getElementById(panelId).classList.remove('open');
    const clientWidth = this.collapsedMenuWidth;
    document.getElementById(panelId).style.marginLeft = clientWidth + 'px';
    document.getElementById(panelId).style.width = '0';

    setTimeout(() => {
      if (
        !(
          this.isMenuHover ||
          this.asideContainerPinned ||
          this.isSubmenuActive ||
          this.isSubmenuHover
        )
      ) {
        this.sidebar.hide();
      }
    }, 300);
  }

  logoutUser(): void {
    this.coreService.logout();
  }

  assignCopy(): void {
    this.currentSubmenuItems = Object.assign([], this.submenuDetails.submenu);
  }

  /**
   *
   * Filter Sub menu
   *
   * @memberof SidebarComponent
   */
  filterSubmenu(): void {
    const val = this.filterVal;
    if (!val || val === '') {
      this.currentSubmenuItems = [...this.submenuDetails.submenu];
    } else {
      this.currentSubmenuItems = this.filterItems(
        this.submenuDetails.submenu,
        val
      );
    }
  }

  filterItems(items: any[], val: string): any[] {
    const filteredItems: any[] = [];

    items.forEach((item) => {
      const subItemText = this.translateService.instant(item.text);

      if (subItemText.toLowerCase().includes(val.toLowerCase())) {
        filteredItems.push(item);
      }

      if (item.submenu && item.submenu.length > 0) {
        const filteredSubmenu = this.filterItems(item.submenu, val);
        if (
          filteredSubmenu.length > 0 &&
          !filteredItems.some((f) => f === item)
        ) {
          const newItem = { ...item, submenu: filteredSubmenu };
          filteredItems.push(newItem);
        }
      }
    });

    return filteredItems;
  }

  /**
   * It calculates the number of columns required to display the items, called evertime when we open menu
   *
   * @memberof SidebarComponent
   */
  calculateColumnsMenu(): void {
    this.menuColumns = 1;
    this.menuHeight = 0;
    let menuClientHeight = 40;
    if (document.getElementsByClassName('level-2')[0]) {
      menuClientHeight =
        document.getElementsByClassName('level-2')[0].scrollHeight;
    }

    const searchContainerHeight =
      document.getElementById('search-container').scrollHeight;
    this.currentSubmenuItems.forEach(
      (element: {
        submenu: [];
        noDisplayText: boolean;
        shouldDisplayAtTop: boolean;
        key: string;
      }) => {
        this.menuHeight += menuClientHeight; // line-height of individual li is 20 + 16 padding top and bottom
        element.submenu.forEach((item: { displayHeader: boolean }) => {
          this.menuHeight += menuClientHeight;
          if (
            this.menuHeight >=
            this.containerHeight - searchContainerHeight - 50
          ) {
            ++this.menuColumns;
            this.menuHeight = 0;
            element.shouldDisplayAtTop = true;
            item.displayHeader = true;
          }
        });
        if (element?.key === 'tenantList') {
          this.isTenantList = true;
        }
      }
    );
  }

  /**
   *
   * Switch tenant
   *
   * @memberof SidebarComponent
   */
  switchTenant(
    subItem: { text?: string; key?: number },
    redirect: RedirectParam | string = undefined
  ): void {
    /* Defect ID : snow 7708 */
    const tenant = new Tenant();
    tenant.name = subItem.text;
    tenant.tenantId = subItem.key;
    const token = this.authService.getTokenData();

    if (token && token.tenant !== tenant.tenantId) {
      this.coreService
        .switchTenant(token.sub, Number(tenant.tenantId))
        .subscribe({
          next: () => {
            this.authService
              .silentRefresh()
              .then((event) => {
                // accept all Events because Oidc library version is buggy..
                if (
                  event instanceof OAuthSuccessEvent ||
                  tenant.tenantId === Number(this.authService.getTenant())
                ) {
                  this.tenant = Number(this.authService.getTenant());

                  this.translateService
                    .get('CORE.messages.tenant_switched', {
                      tenant: tenant.name,
                    })
                    .subscribe({
                      next: (message) => {
                        sessionStorage.removeItem('permittedMenuItems');
                        this.toast.showToast(message, false, 2000);
                        setTimeout(() => {
                          // if: need redirect url as string to use window.location to do page refresh
                          // else if: if we want to redirect after tenant switch without page refresh
                          // else: reloading page & removing query parameters e.g. filter param from platform->operator-dashboard
                          if (typeof redirect === Constants.DATA_TYPE.STRING) {
                            window.location.href = redirect as string;
                          } else if (
                            typeof redirect === Constants.DATA_TYPE.OBJECT
                          ) {
                            redirect = redirect as RedirectParam;
                            this.router.navigate(redirect?.url, {
                              queryParams: redirect?.params,
                            });
                          } else {
                            const urlWithoutParamQuery =
                              window.location.href.split(/[?#]/)[0];
                              window.location.href = urlWithoutParamQuery;
                          }
                        }, 2000);
                      },
                    });
                } else {
                  this.translateService
                    .get('CORE.messages.tenant_switched_logout', {
                      tenant: tenant.name,
                    })
                    .subscribe({
                      next: (message) => {
                        this.toast.showToast(message, false, 2000);
                        setTimeout(() => this.coreService.logout(), 2000);
                      },
                    });
                }
              })
              .catch((err) => {
                this.translateService
                  .get('CORE.messages.tenant_switched_logout', {
                    tenant: tenant.name,
                  })
                  .subscribe({
                    next: (message) => {
                      this.toast.showToast(message, false, 2000);
                      setTimeout(() => this.coreService.logout(), 2000);
                    },
                  });
                console.error('OAUTH ERROR :', err);
              });
          },
        });
    }
  }

  /**
   * Sets the menu data.
   */
  setMenuItems(isExplicitLoad?: boolean): void {
    let linksToDisableMenus = [];
    if(this.menuService?.disabledFeatures?.common?.frontendNavigation?.hideMenus && Object.keys(this.menuService?.disabledFeatures?.common?.frontendNavigation?.hideMenus).length > 0){
      linksToDisableMenus = Object.values(this.menuService?.disabledFeatures?.common?.frontendNavigation.hideMenus); // ['/settings/cashflow-enhancements', '/settings/cashflow-type-assignment', '/masterdata/externalSystem', '/alevate-rule-engine-app/recipe', '/alevate-rule-engine-app/are-config/are-config-list', '/masterdata/liquidity-types', "/cashmanagement-app/unprocessed-cashflows", '/masterdata/collection-agency','/masterdata/customer','/masterdata/collection-status', "/settings/customerMatchingCriteriaConfiguration"];
    }
    
    if(isExplicitLoad){
      this.menuItems = this.findAndRemoveLinks(linksToDisableMenus, this.menuService.getMenu());
      this.bottomMenuItems = this.findAndRemoveLinks(linksToDisableMenus, this.menuService.getBottomMenu());
    }else{
      this.menuService.addMenu();
      this.menuService.addBottomMenu();
      this.menuItems = this.findAndRemoveLinks(linksToDisableMenus, this.menuService.getMenu());
      this.bottomMenuItems = this.findAndRemoveLinks(linksToDisableMenus, this.menuService.getBottomMenu());
      this.setTenantData();
    }
  }

  findAndRemoveLinks(links, menus) {
    if(links.length > 0) {
      for (const menuItem of menus) {
        if (menuItem.submenu) {
          for (const submenuItem of menuItem.submenu) {
                if(submenuItem.submenu) {
                  submenuItem.submenu = submenuItem.submenu.filter(linkMenu => !links.includes(linkMenu.link));
                }
          }
        }
      }
    }
    return menus
  }

  /**
   * Sets the tenant data.
  */
  setTenantData(): void {
    const token = this.authService.getTokenData();
    let isSystemTenant = false;
    if (token.context === Constants.PLATFORM_USER_TYPES.context.OPERATOR) {
      this.isLoggedInUserOperator = true;
    } else if (token.context === Constants.PLATFORM_USER_TYPES.context.SYSTEM) {
      isSystemTenant = true;
    }
    const modifyTenantList = [];
    if (token) {
      this.tenant = token.tenant;
    }
    const tenantIndex = this.menuItems.findIndex(
      (menuItem) => menuItem.key === 'tenant'
    );
    if (this.tenants?.length === 0) {
      this.coreService.getAssignedTenants().subscribe({
        next: (tenants) => {
          this.tenants = this.sortTenantsByDisplayName(tenants);
          this.tenants.forEach((ele) => {
            const tenantMenuData = {
              key: ele.id,
              text: ele.displayName,
              link: '',
              icon: '',
              isActive: ele.id === this.tenant,
              moduleId: ele.id,
              subModuleLevel: SUBMODULES_LEVEL.TWO,
              submenu: [],
              canLandingPage: false,
            };
            if (this.isLoggedInUserOperator) {
              ele?.managedTenants?.forEach((assignedCustomers) => {
                tenantMenuData.submenu.push({
                  key: assignedCustomers.id,
                  text: assignedCustomers.displayName,
                  link: '',
                  icon: '',
                  isActive: assignedCustomers.id === this.tenant,
                  moduleId: assignedCustomers.id,
                  subModuleLevel: SUBMODULES_LEVEL.THREE,
                  canLandingPage: false,
                });
              });
            }
            sessionStorage.setItem(
              'tenantData',
              JSON.stringify(tenantMenuData)
            );
            modifyTenantList.push(tenantMenuData);
          });
          if (!isSystemTenant) {
            this.menuItems[tenantIndex].submenu[0].submenu = modifyTenantList;
          }
        },
        error: () => {
          this.menuItems[tenantIndex].submenu[0].submenu = [];
          this.toast.showToast(
            this.translateService.instant(
              'FEATURES.PROFILE.MESSAGES.TENANT_NOT_FOUND'
            ),
            true
          );
        },
      });
    }
    this.setCurrentActiveLink();
  }

  sortTenantsByDisplayName(tenants: any) {
    tenants.sort((a, b) => a.displayName.localeCompare(b.displayName));
    tenants.forEach(tenant => {
      tenant.managedTenants.sort((a, b) => a.displayName.localeCompare(b.displayName));
    });

    return tenants;
  }

  /**
   * Checks if the current tenant is in preparation mode and sets the session storage key
   * accordingly.
   */
  tenantInPreparation(): void {
    if (
      sessionStorage.getItem(Constants.TENANT_PREPARATION.SESSION_KEY) !== null
    ) {
      sessionStorage.removeItem(Constants.TENANT_PREPARATION.SESSION_KEY);
    }
    this.coreService.getAllTenants().subscribe({
      next: (tenants) => {
        const tokenData = this.authService.getTokenData();
        const hasContextAndLevel =
          Constants.TENANT_PREPARATION.CONTEXT in tokenData &&
          Constants.TENANT_PREPARATION.LEVEL in tokenData;
        const tenantId = this.authService.getTenant();
        const filteredTenant = tenants.find(
          (tenant) => tenant[Constants.TENANT_PREPARATION.TENANT_ID] === tenantId
        );

        const isMatch =
          hasContextAndLevel &&
          Object.entries(tokenData).filter(
            ([key, value]) =>
              (key === Constants.TENANT_PREPARATION.CONTEXT &&
                value === Constants.TENANT_PREPARATION.CONTEXT_CUSTOMER) ||
              (key === Constants.TENANT_PREPARATION.LEVEL &&
                (value === Constants.TENANT_PREPARATION.LEVEL_ADMIN ||
                  value === Constants.TENANT_PREPARATION.LEVEL_SINGLE_LEVEL_USER))
          ).length === 2;

        if (isMatch && filteredTenant) {
          const tenantStatus =
            filteredTenant[Constants.TENANT_PREPARATION.STATUS] ===
            Constants.TENANT_PREPARATION.STATUS_PREPARATION;
          if (tenantStatus) {
            sessionStorage.setItem(
              Constants.TENANT_PREPARATION.SESSION_KEY,
              Constants.TENANT_PREPARATION.STATUS_PREPARATION
            );
          } else {
            sessionStorage.removeItem(Constants.TENANT_PREPARATION.SESSION_KEY);
          }
        }
      },
    });
  }

  /**
   * Subscribes to feature toggles updates and sets `isCashAppMenuAvailable` accordingly. 
   * `isCashAppMenuAvailable` will be `true` when the CASH_APP_MENU feature is enabled.
   */
    getFeatureToggle(): void {
      this.featureToggleSubscription = this.featureToggleService
        .getFeatureToggles(environment)
        .pipe(
          map(
            () =>
              !this.featureToggleService.isFeatureDisabled(
                Constants.FEATURE_TOGGLE.CASH_APP_MENU
              )
          )
        )
        .subscribe((isEnabled) => this.isCashAppMenuAvailable.next(isEnabled));
    }
}
